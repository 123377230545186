import React,{useEffect,useState} from "react";
import api from "../api/api";
import { Avatar,Container,Grid,Paper,CardHeader,CardActionArea } from "@mui/material";
import { useLocation,useNavigate } from "react-router-dom";


const PlcCliente =() =>{
    const navigate = useNavigate();
    const location = useLocation();
    const [plcCliente,setPlcCliente]=useState([]);
    const [cliente,setCliente] = useState([]);

    useEffect(() => {
        const fetchData = async () =>{
                    let resultCli = await api.get('clienti', {headers: {'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`},params:{ID:JSON.parse(localStorage.getItem('utente')).auth===1?location.state.cliente:JSON.parse(localStorage.getItem('utente')).cliente}})
                    setCliente(resultCli.data.data[0]);
    await api.get('plc', {headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
        },params:{id:JSON.parse(localStorage.getItem('utente')).auth===1?location.state.cliente:JSON.parse(localStorage.getItem('utente')).cliente}})
        .then( res=> {
            setPlcCliente(res.data.data)
            if(res.data.data.length === 1){
                let plc  = res.data.data[0];
                navigate(`/PlcData`, {state: {seriale:plc.Seriale,zone:plc.Zone,lat:resultCli.data.data[0].Lat,lon:resultCli.data.data[0].Lon}})
            }


        })


    }

fetchData()

    },[]);


return (


    <Container style={{marginTop:"100px"}}>
           <Grid container spacing={2}>


           {
plcCliente.map((plc,index)=>
<Grid item xs={12} key={plc.Seriale} >
<CardActionArea onClick={()=>navigate(`/PlcData`, {state: {seriale:plc.Seriale,zone:plc.Zone,lat:cliente.Lat,lon:cliente.Lon}})}>
<Paper style={{padding:"10px"}}>
<CardHeader
  avatar={
    <Avatar sx={{ bgcolor: "blue" }}>{index+1}</Avatar>
  }
  title={`Seriale: `+ plc.Seriale  + (plc.Descrizione ===null?"": ` - `+ plc.Descrizione )}
/>


</Paper>
</CardActionArea>
    </Grid>


)

}


           </Grid>

</Container>


)

}

export default PlcCliente;

