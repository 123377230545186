import react,{useState}  from 'react';
import api from '../api/api';
import {Dialog,DialogTitle,DialogContent,TextField,Typography,Button,Box,Grid,InputLabel,FormControl,Select,MenuItem} from '@mui/material';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DettaglioZone = (props) =>{
    const [open, setOpen] = useState(false);
    const [zone,setZone] = useState([]);
    const [augelli,setAugelli] = useState([]);

const handleOpen = async() => {
        await api.get('dettaglioZone', {headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
        },params:{seriale:props.seriale}})
        .then( res=> {
            setZone(res.data.data);
           
        })
        await api.get('tipoAugelli', {headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
      },params:{tipologia:""}})
      .then( res=> {
        setAugelli(res.data.data);
    
      })

        setOpen(true)
    
    }
const handleChange = (e,index) => {

let zTemp = [...zone];
zTemp[index][e.target.name] = e.target.value;
setZone(zTemp);
}

const handleSave = async (index) => {

try{
    await api.put('dettaglioZone',{zona:zone[index]},
    { headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
    }}  )
    .then(res => {
        alert("Salvataggio effettuato con successo");
  
      })
}catch{

}

}
const handleChangePressione = (e,index) => {

let augello = augelli.filter(a => a.Pressione === e.target.value);
let z = [...zone];
z[index].ConsumoAugello = augello[0].Portata;
z[index].Pressione = e.target.value;
setZone(z);

}

const handleChangeTipologia = async (e,index) => {

  await api.get('tipoAugelli', {headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
},params:{tipologia:e.target.value}})
.then( res=> {
  setAugelli(res.data.data);

})
let z = [...zone];
z[index].TipoAugelli = e.target.value;
z[index].ConsumoAugello ="";
z[index].Pressione = "";
setZone(z);


}

const handleClose = () => setOpen(false);



    return (
        <div>
            <Button onClick={handleOpen} id={props.idButton} sx={{display:"None"}}  endIcon={<i class="bi bi-plus"></i>}>Aggiungi</Button>
            
          <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
              <DialogTitle>Dettaglio Zone</DialogTitle>
              <DialogContent dividers={true}>
                <Grid container spacing={1}>        {
            zone?.map((zona,index)=> {
                return (
                <>
              <Grid item xs={12}><h3>Zona {index+1} - {zona.Nome}</h3> </Grid>
              <Grid item xs={12} >
            
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                fullWidth
                onChange={(e) => handleChange(e,index)}
                label="Motori"
                name="Motori"
                required
                value={zona.Motori}
                type="number"
                variant="outlined"
              />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                onChange={(e) => handleChange(e,index)}
                fullWidth
                label="KW"
                name="KW"
                type="number"
                required
                value={zona.KW}
                variant="outlined"
              />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Tipo Ugelli</InputLabel>
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Tipo Ugelli" defaultValue="Alimentazione" value={zona.TipoAugelli}
                      onChange={(e) => handleChangeTipologia(e,index)}
                      >
                        <MenuItem value={"Sala"}>Sala</MenuItem>
                        <MenuItem value={"Alimentazione"}>Alimentazione</MenuItem>
                     </Select>
                  </FormControl>
             </Grid>
                <Grid item xs={12} md={4}>
                <TextField
                onChange={(e) => handleChange(e,index)}
                fullWidth
                label="Ugelli"
                name="Augelli"
                required
                value={zona.Augelli}
                variant="outlined"
              />
                </Grid>
                <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Pressione</InputLabel>
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Tipo Augelli" defaultValue="Alimentazione" value={zona.Pressione}
                      onChange={(e) => handleChangePressione(e,index)}
                      //onChange={handleChange}
                      >
                        {augelli?.map(a=> 
                        <MenuItem value={a.Pressione}>{a.Pressione}</MenuItem>
                        )

                        }
   
                     </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                <TextField
                onChange={(e) => handleChange(e,index)}
                fullWidth
                label="Consumo"
                name="ConsumoAugello"
                required
                value={zona.ConsumoAugello}
                variant="outlined"
                inputProps={
                  { readOnly: true, }
                }
                
              />
                </Grid>
                <Grid item xs={12} >
                <TextField
              onChange={(e) => handleChange(e,index)}
                fullWidth
                label="Note"
                name="Note"
                required
                multiline
                value={zona.Note}
                variant="outlined"
              />
                </Grid>
                <Grid item xs={12}>
                <Button variant="contained" color="success" size="small" onClick={() => handleSave(index)}>Salva</Button>
                </Grid>
        
                </>
                )
           
            }
                
                )
        }
        </Grid>

            </DialogContent >
          </Dialog>
        </div>
      );

    
}


export default DettaglioZone;