
import React,{useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/api";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ReferenceLine ,Bar,BarChart} from 'recharts';

import { TextField,Container,Grid,Paper,CardHeader, Button, CardActionArea, CardActions,Box,Card,CardMedia,Typography,CardContent,Avatar  } from "@mui/material";

import ThermostatIcon from '@mui/icons-material/Thermostat';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { SettingsApplicationsSharp } from "@mui/icons-material";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/locale/it";
import LogExcel from "./LogExcel";


var dBefore = new Date();
dBefore.setDate(dBefore.getDate() -7)
const Analysis = (props) =>{
  const [dataDa, setDa] = useState(moment(dBefore));
  const [dataA, setA] = useState(moment(new Date()));
  const [oreSopraSoglia, setSopraSoglia] = useState(0);
  const [dataGrafico,setDataGrafico] = useState([]);
  const [salute,setSalute] = useState([{name:"THI",percSott:0,percSopra68:0,percSSopra80:0}]);
  const [cooling,setCooling] = useState({tot:0,percentuale:0});
  const [thiS68,setThiS68] = useState({tot:0,percentuale:0});
  const [thiS80,setThiS80] = useState({tot:0,percentuale:0});

  const [settings,setSettings] = useState(JSON.parse(sessionStorage.getItem("settings")));

const calcPercentage = (data) => {
    let nSopra =data.filter(dato=> dato.THI > JSON.parse(sessionStorage.getItem("settings")).soglia3THI)
    let percSopraSoglia = Math.round(((nSopra.length / data.length ))*100);
    setSopraSoglia(isNaN(percSopraSoglia)?0:percSopraSoglia);
}

const loadData = async(tipo,valore) =>{
  
  let da= tipo==="Da"?valore:dataDa;
  let a = tipo==="A"?valore:dataA;


    await api.get('plcDataAnalysis', {headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
    },params:{seriale:props.seriale,dataInizio:moment(da).format("YYYY-MM-DD"),dataFine:moment(a).format("YYYY-MM-DD")}})
    .then( async res=> {
      console.log(res.data.data)
        await setDataGrafico(res.data.data.all);
        calcPercentage(res.data.data.all);
        setSalute([res.data.data.last24h]);
        setCooling(res.data.data.cooling);
        setThiS68(res.data.data.thiS68);
        setThiS80(res.data.data.thiS80);
    })
    
} 



useEffect(() => {

   const fetchData = async () =>{
    await api.get('plcDataAnalysis', {headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
  },params:{seriale:props.seriale,dataInizio:moment(dataDa).format("YYYY-MM-DD"),dataFine:moment(dataA).format("YYYY-MM-DD")}})
  .then( async res=> {
  
      await setDataGrafico(res.data.data.all);
      console.log(res.data.data);
      calcPercentage(res.data.data.all);
      setSalute([res.data.data.last24h]);
      setCooling(res.data.data.cooling);
      setThiS68(res.data.data.thiS68);
      setThiS80(res.data.data.thiS80);
  })
  


}

fetchData()

},[]);

const formatXAxis =(tickItem)=> {
  // If using moment.js

  return tickItem===0?"":moment(new Date(tickItem.split(" ")[0])).format('DD/MM')
  }



const renderElements = () => {
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
     let date = label.split("/")[1]+"/"+label.split("/")[0]+"/"+label.split("/")[2];
    let data = date.split(" ");
      return (

        <Card style={{backgroundColor:"white",border:"0.5px solid black",padding:"5px"}}>
          <p className="label">{data[0]} {data[1]}:00</p>
          <p className="intro"> </p>
          <div className="desc" style={{alignContent:"center"}}><center>THI<br/> <Avatar sx={{ width: 30, height: 30}} className={ Math.round(payload[0].value)>=settings.soglia4THI?"soglia4":( Math.round(payload[0].value)>=settings.soglia3THI?"soglia3":( Math.round(payload[0].value)>=settings.soglia2THI?"soglia2":"soglia1"))}>
        {Math.round(payload[0].value)}
                </Avatar></center></div>
        </Card>
      );
    }
  
    return null;
  };
  const CustomTooltipTemp = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
     let date = label.split("/")[1]+"/"+label.split("/")[0]+"/"+label.split("/")[2];
    let data = date.split(" ");
      return (

        <Card style={{backgroundColor:"white",border:"0.5px solid black",padding:"5px"}}>
          <p className="label">{data[0]} {data[1]}:00</p>
          <Grid container spacing={1} >
          <Grid item sx={6} alignItems="center" >
          <div className="desc" style={{alignContent:"center"}}><center>TEMP<br/> <Avatar sx={{ width: 30, height: 30}} className={ Math.round(payload[0].value)>=settings.soglia4TEMP?"soglia4":( Math.round(payload[0].value)>=settings.soglia3TEMP?"soglia3":( Math.round(payload[0].value)>=settings.soglia2TEMP?"soglia2":"soglia1"))}>
        {Math.round(payload[0].value)}
                </Avatar></center></div>
          </Grid>
          <Grid item sx={6} alignItems="center" >
          <div className="desc" style={{alignContent:"center"}}><center>UM<br/> <Avatar sx={{ width: 30, height: 30}} className={Math.round(payload[1].value)>=settings.soglia4UM?"soglia4":(Math.round(payload[1].value)>=settings.soglia3UM?"soglia3":(Math.round(payload[1].value)>=settings.soglia2UM?"soglia2":"soglia1"))}>
        {Math.round(payload[1].value)}
                </Avatar></center></div>
         </Grid>
          </Grid>
        
                
        </Card>
      );
    }
  
    return null;
  };

  const CustomTooltipLast24h = ({ active, payload, label }) => {
  
    if (active && payload && payload.length) {
     let verde = payload[0]?.value;
     let giallo = payload[1]?.value;
     let rosso = payload[2]?.value;
      return (

        <Card style={{backgroundColor:"white",border:"0.5px solid black",padding:"5px"}}>
          <p className="label"><b>{"<68 : " + verde +"%"}</b></p>
          <p className="intro"><b> {">68 : " + giallo+"%"}</b></p>
          <p className="intro"><b> {">80 : " + rosso+"%"}</b></p>

        </Card>
      );
    }
  
    return null;
  };


switch (props.tipo){

case "THI":
return (
<>
<Grid container spacing={0.5}>
<Grid item xs={12} md={3}>


<CardHeader
    title="Report THI"
  >

 </CardHeader>     

   </Grid>
<Grid item xs={6} md={3} style={{paddingTop:"25px"}}>

<LocalizationProvider dateAdapter={DateAdapter} >
      <DatePicker 
        label="Da:"
        value={dataDa}
        onChange={(newValue) => {
           setDa(newValue);
          loadData("Da",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   <Grid item xs={6} md={3} style={{paddingTop:"25px"}}>

<LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label="A:"
        minDate={dataDa}
        value={dataA}
        onChange={(newValue) => {
          setA(newValue);
          loadData("A",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   <Grid item xs={6} md={3} >
   <LogExcel data={dataGrafico} />
    </Grid> 

   
    

   <Grid item xs={12} md={6} container>
   <Grid item xs={12}>
   <center><h3>THI</h3></center>
<ResponsiveContainer width="99%" height="99" aspect={window.innerWidth<800?1:2} >
<LineChart data={dataGrafico} margin={{top: 5,}}>
      <XAxis dataKey="Data"
  interval={(dataGrafico.length/23)>19 && window.innerWidth<800 ?46:23}
      angle={90}   
        dy={20}
        height={70}
        tickFormatter={formatXAxis}
    />
      <YAxis dataKey="THI" domain={[40,90]}/>
      <Tooltip content={<CustomTooltip />} />
      <Legend  verticalAlign="top"/>
      <Line type="monotone" dataKey="THI" stroke="#00a94e" dot={false} strokeWidth={2}  />
      <ReferenceLine y={JSON.parse(sessionStorage.getItem("settings")).soglia3THI} stroke="red" strokeWidth={1} dot={false}  />
      <ReferenceLine y={JSON.parse(sessionStorage.getItem("settings")).soglia4THI} stroke="#7b002c" strokeWidth={1} dot={false}  />
    </LineChart>
    </ResponsiveContainer>   
    </Grid>
    <Grid item xs={12} style={{paddingLeft:"20px"}} container>
      <Grid item xs={6}> 
      ORE COOLING <br />
      {"ORE THI >68 STRESS MODERATO"} <br />
      {"ORE THI >80 STRESS GRAVE"}  <br />
      </Grid>
      <Grid item xs={6}> 
       TOT <input size="4"  value={cooling.tot}/> % <input size="4" value={cooling.percentuale}/> <br />  
       TOT <input size="4" value={thiS68.tot}/> % <input size="4" value={thiS68.percentuale}/> <br />
       TOT <input size="4" value={thiS80.tot}/> % <input size="4" value={thiS80.percentuale}/> <br />
      </Grid>
            
    </Grid>
 </Grid>


 
 <Grid item xs={12} md={6} >
   <center><h3>Accumulate Heat Load Index (AHLI)</h3></center>
<ResponsiveContainer width="99%" height="99%" aspect={window.innerWidth<800?1:2} >

<BarChart width={730} height={250} data={salute} barGap={0} >

  <XAxis  tick={true} dataKey="name" />
  <YAxis domain={[0, 100]}/>
  <Tooltip  content={<CustomTooltipLast24h />} />
  <Bar dataKey="percSott" fill="green" />
  <Bar dataKey="percSopra68" fill="#febe1e" />
  <Bar dataKey="percSopra80" fill="red" />
</BarChart>

    </ResponsiveContainer>    
 </Grid>
 </Grid>
     </>



);

case "TEMP":
    return (
        <>
        <CardHeader
    title="Report Temperatura / Umidità"
    subheader=""
  ></CardHeader>
        
        <Grid container spacing={0.5}>

<Grid item xs={6} md={3} style={{paddingBottom:"20px",paddingLeft:"20px"}}>


<LocalizationProvider dateAdapter={DateAdapter} >
      <DatePicker 
        label="Da:"
        value={dataDa}
        onChange={(newValue) => {
          setDa(newValue);
          loadData();
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   <Grid item xs={6} md={3} style={{paddingBottom:"20px",paddingLeft:"20px"}}>

<LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label="A:"
        value={dataA}
        onChange={(newValue) => {
          setA(newValue);
          loadData();
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   </Grid> 
        <ResponsiveContainer width="99%" height="99%" aspect={window.innerWidth<800?1:3}>
        <LineChart
              data={dataGrafico}
              margin={{
                top: 5,  
              }}
            >
             <XAxis dataKey="Data"
  interval={(dataGrafico.length/23)>19 && window.innerWidth<800 ?46:23}
      angle={90}   
        dy={20}
        height={70}
        tickFormatter={formatXAxis}
    />
              <YAxis dataKey="UM"/>
             <Tooltip content={<CustomTooltipTemp />} />
              <Legend />
              <Line type="monotone" dataKey="TEMP" stroke="#0000FF" dot={false} strokeWidth={2}  />
              <Line type="monotone" dataKey="UM" stroke="#00a94e" dot={false} strokeWidth={2}  />

            </LineChart>
            </ResponsiveContainer>     </>
        );

}


}



return (renderElements());

}

export default Analysis;

