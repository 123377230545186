import React,{useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/api";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ReferenceLine ,Bar,BarChart} from 'recharts';

import { TextField,Container,Grid,Paper,CardHeader, Button, CardActionArea, CardActions,Box,Card,CardMedia,Typography,CardContent,Avatar  } from "@mui/material";

import ThermostatIcon from '@mui/icons-material/Thermostat';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { SettingsApplicationsSharp } from "@mui/icons-material";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/locale/it";

var dBefore = new Date();
dBefore.setDate(dBefore.getDate() -7);
const PlcDettaglioVentoAcqua = (props)=> {
const location = useLocation();
const [dataDa, setDa] = useState(moment(dBefore));
const [dataA, setA] = useState(moment(new Date()));
const [dataVento,setDataVento] = useState([]);
const [dataAcqua,setDataAcqua] = useState([]);
const [consumoVento,setConsumoVento] = useState([]);
const [consumoAcqua,setConsumoAcqua] = useState([]);
const [infoVentilatore,setInfoVentilatore] = useState([]);

const formatXAxis =(tickItem)=> {

    return tickItem===0?"":moment(new Date(tickItem.split(" ")[0])).format('DD/MM')
    }



const loadData = async(tipo,valore) =>{
  
        let da= tipo==="Da"?valore:dataDa;
        let a = tipo==="A"?valore:dataA;
      
      
          await api.get('plcDettaglioVentoAcqua', {headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
          },params:{seriale:location.state.seriale,dataInizio:moment(da).format("YYYY-MM-DD"),dataFine:moment(a).format("YYYY-MM-DD"),zona:location.state.zona}})
          .then( async res=> {
            setDataVento(res.data.data.vento);
            setDataAcqua(res.data.data.acqua);
            setInfoVentilatore(res.data.data.dettaglio);
            setConsumoAcqua(res.data.data.consumoAcqua);
            setConsumoVento(res.data.data.consumoVento);
           
          })
          
      } 


const handleButton = async (tipo) =>{
    let da = new Date ();
    let a = new Date();
switch(tipo){
case "Oggi": 

break;
case "Settimana": moment(da= da.setDate(da.getDate() -7));break;
case "Mese": da= new Date(da.setMonth(da.getMonth()-1)); break;
case "Anno": da= new Date(new Date().setFullYear(new Date().getFullYear() - 1));break;

}


setA(moment(a));
setDa(moment(da));
await api.get('plcDettaglioVentoAcqua', {
    headers: {'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`},
    params:{seriale:location.state.seriale,dataInizio:moment(da).format("YYYY-MM-DD"),dataFine:moment(a).format("YYYY-MM-DD"),zona:location.state.zona}})
   .then( async res=> {
    setDataVento(res.data.data.vento);
    setDataAcqua(res.data.data.acqua);
    setInfoVentilatore(res.data.data.dettaglio);
    setConsumoAcqua(res.data.data.consumoAcqua);
    setConsumoVento(res.data.data.consumoVento);
   })

}     

useEffect(() => {
    const fetchData = async () =>{ 
           
    await api.get('plcDettaglioVentoAcqua', {
    headers: {'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`},
    params:{seriale:location.state.seriale,dataInizio:moment(dataDa).format("YYYY-MM-DD"),dataFine:moment(dataA).format("YYYY-MM-DD"),zona:location.state.zona}})
   .then( async res=> {
    setDataVento(res.data.data.vento);
    setDataAcqua(res.data.data.acqua);
    setInfoVentilatore(res.data.data.dettaglio);
    setConsumoAcqua(res.data.data.consumoAcqua);
    setConsumoVento(res.data.data.consumoVento);
  
   })
   
 
 
 }
 
 fetchData()
 
 },[]);

    return(
    <Container style={{marginTop:"100px"}}>

          <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                      <Card sx={{ padding:"1px",marginBottom:"10px" }}>
                          <CardHeader title={location.state.title} subheader={location.state.subtitle} />
                          <Grid container spacing={0.5}>
                          <Grid item xs={12} md={6} >
                          <center><h3>Corrente Zona</h3>
                          consumo totale <input size={consumoVento.toString().length} defaultValue={consumoVento}/> KW
                          </center>
                              <ResponsiveContainer width="99%" height="99" aspect={window.innerWidth<800?1:2} >
                                <LineChart data={dataVento} margin={{top: 5,}}>
                                    <XAxis dataKey="name"
                                           interval={(dataVento.length/23)>19 && window.innerWidth<800 ?46:23}
                                           angle={90}   
                                           dy={20}
                                           height={70}
                                           tickFormatter={formatXAxis}
                                    />
                                    <YAxis  />
                                    <Tooltip  />
                                    <Line type="monotone" dataKey="kwh" stroke="#00a94e" dot={false} strokeWidth={2}  />
                                </LineChart>
                              </ResponsiveContainer>   
                        
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <center><h3>Acqua Zona</h3>
                            consumo totale <input size={consumoAcqua.toString().length} defaultValue={consumoAcqua}/> L
                            </center>
                              <ResponsiveContainer width="99%" height="99" aspect={window.innerWidth<800?1:2} >
                                <LineChart data={dataAcqua} margin={{top: 5,}}>
                                    <XAxis dataKey="name"
                                           interval={(dataAcqua.length/23)>19 && window.innerWidth<800 ?46:23}
                                           angle={90}   
                                           dy={20}
                                           height={70}
                                           tickFormatter={formatXAxis}
                                    />
                                    <YAxis  />
                                    <Tooltip  />
                                    <Line type="monotone" dataKey="lh" stroke="#00a94e" dot={false} strokeWidth={2}  />
                                </LineChart>
                              </ResponsiveContainer>   
                          
                          </Grid>
                          <Grid item container xs={12} md={6}  justifyContent="center"  direction="row">
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}}  onClick={() =>handleButton("Oggi")}>Oggi</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Settimana")}>Settimana</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Mese")}>Mese</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Anno")}>Anno</Button>
    </Grid> 
                          <Grid item xs={6} md={3} style={{paddingTop:"15px"}}>

<LocalizationProvider dateAdapter={DateAdapter} >
      <DatePicker 
        label="Da:"
        value={dataDa}
        onChange={(newValue) => {
           setDa(newValue);
          loadData("Da",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   <Grid item xs={6} md={3} style={{paddingTop:"15px"}}>

<LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label="A:"
        minDate={dataDa}
        value={dataA}
        onChange={(newValue) => {
          setA(newValue);
          loadData("A",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

    </Grid>
   
    <Grid item xs={12} md={6} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",}}>
    <TextField
                fullWidth
                label="Numero Motori"
                name="Motori"
                readOnly={true}
                value={infoVentilatore.Motori?infoVentilatore.Motori:""}
                variant="outlined"
              />
   </Grid>
   <Grid item xs={12} md={6} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",}}>

    <TextField 
               
                fullWidth
                label="KW Motori"
                name="KW"
                readOnly
                value={infoVentilatore.KW?infoVentilatore.KW:""}
                variant="outlined"
              />
   </Grid>
   <Grid item xs={12} md={6} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",}}>
    <TextField 
               
                fullWidth
                label="Numero Ugelli"
                name="Augelli"
                readOnly
                value={infoVentilatore.Augelli?infoVentilatore.Augelli:""}
                variant="outlined"
              />
   </Grid>
   <Grid item xs={12} md={6} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",}}>
    <TextField 
               
               fullWidth
               label="Pressione"
               name="Pressione"
               readOnly
               value={infoVentilatore.Pressione?infoVentilatore.Pressione:""}
               variant="outlined"
             />
   </Grid>
   <Grid item xs={12} md={12} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",paddingBottom:"15px"}}>
   <TextField 
               
               fullWidth
               label="Note"
               name="Note"
               readOnly
               value={infoVentilatore.Note?infoVentilatore.Note:""}
               variant="outlined"
             />
   
   </Grid>
                          </Grid>
                      </Card>
                  </Grid>
              </Grid>
          </Box>
    </Container>
   
   )
}


export default PlcDettaglioVentoAcqua;