import React, { useEffect, useState } from "react";
import { Container,Grid,Paper,TextField,OutlinedInput,InputAdornment,Button,Divider,Snackbar,Alert } from "@mui/material";
import api from "../api/api";

const Settings = () => {
const [settings,setSettings] = useState(JSON.parse(sessionStorage.getItem("settings")));
const [open, setOpen] = useState(false);
const [snack,setSnack] = useState({severity:"success",messaggio:"Salvataggio Effettuato correttamente"});

const handleChangeValue = (e) => {

let tempSetting = {...settings};
tempSetting[e.target.name] = e.target.value;

setSettings(tempSetting);

}

const handleSaveButton = async () => {

try{
    await api.put('settings',{impostazioni:settings},
    { headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
    }}  )
    .then(res => {
    
  
      })
      sessionStorage.setItem("settings",JSON.stringify(settings));

      let snackOption={ ...snack};
      snackOption.severity="success";
      snackOption.messaggio="Soglie Aggiornate con successo";

      setSnack(snackOption);
      setOpen(true);


    }
    catch{
        let snackOption={ ...snack};
        snackOption.severity="error";
        snackOption.messaggio="Errore nel salvataggio";
     
        setSnack(snackOption);
        setOpen(true);
    }


}


const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
}

return (<Grid sx={{marginTop:"80px"}} container spacing={2}>
    <Snackbar open={open} autoHideDuration={6000} style={{marginTop:"50px"}} anchorOrigin={{vertical:"top",horizontal: "right" }} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snack.severity} sx={{ width: '100%' }}>
     {snack.messaggio}
        </Alert>
      </Snackbar>
<Grid item xs={12} md={4}>

<Paper sx={{padding:"10px"}}>
<b>Soglia THI</b> <br/><br/>

<Grid container spacing={2}>
<Grid item xs={12} md={6} >
    
<TextField id="outlined-basic" fullWidth  label="Soglia 1" variant="outlined" type="number" value={settings.soglia1THI} name="soglia1THI" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#00a94e"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 2" variant="outlined" type="number" value={settings.soglia2THI} name="soglia2THI" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#f8e71c"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 3" variant="outlined" type="number" value={settings.soglia3THI} name="soglia3THI" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"red"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 4" variant="outlined" type="number" value={settings.soglia4THI} name="soglia4THI" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#7b002c"}}/>
    </Grid>
    <Grid item xs={12} md={12}>
    <Button  variant="contained" sx={{backgroundColor:"#00a94e",'&:hover':{backgroundColor:"green"}}} onClick={() => handleSaveButton()}>Salva</Button>
    </Grid>
      </Grid>

</Paper>

</Grid>
<Grid item xs={12} md={4}>
<Paper sx={{padding:"10px"}}><b> Soglia Temperatura</b><br/><br/>
<Grid container spacing={2}>
<Grid item xs={12} md={6} >
    
<TextField id="outlined-basic" fullWidth  label="Soglia 1" variant="outlined" type="number" value={settings.soglia1TEMP} name="soglia1TEMP" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#00a94e"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 2" variant="outlined" type="number" value={settings.soglia2TEMP} name="soglia2TEMP" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#f8e71c"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 3" variant="outlined" type="number" value={settings.soglia3TEMP} name="soglia3TEMP" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"red"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 4" variant="outlined" type="number" value={settings.soglia4TEMP } name="soglia4TEMP" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#7b002c"}}/>
    </Grid>
    <Grid item xs={12} md={12}>
    <Button  variant="contained" sx={{backgroundColor:"#00a94e",'&:hover':{backgroundColor:"green"}}} onClick={() => handleSaveButton()}>Salva</Button>
    </Grid>
      </Grid>


</Paper></Grid>
<Grid item xs={12} md={4}>
<Paper sx={{padding:"10px"}}><b>Soglia Umidità</b><br/><br/>
<Grid container spacing={2}>
<Grid item xs={12} md={6} >
    
<TextField id="outlined-basic" fullWidth  label="Soglia 1" variant="outlined" type="number"  value={settings.soglia1UM} name="soglia1UM" onChange={(e) => handleChangeValue(e)} />
<Divider sx={{marginTop:"3px",background:"#00a94e"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 2" variant="outlined" type="number" value={settings.soglia2UM} name="soglia2UM" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#febe1e"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 3" variant="outlined" type="number" value={settings.soglia3UM } name="soglia3UM" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"red"}}/>
    </Grid>
    <Grid item xs={12} md={6}>
        
<TextField id="outlined-basic" fullWidth  label="Soglia 4" variant="outlined" type="number" value={settings.soglia4UM} name="soglia4UM" onChange={(e) => handleChangeValue(e)}/>
<Divider sx={{marginTop:"3px",background:"#7b002c"}}/>
    </Grid>
    <Grid item xs={12} md={12}>
    <Button  variant="contained" sx={{backgroundColor:"#00a94e",'&:hover':{backgroundColor:"green"}}} onClick={() => handleSaveButton()}>Salva</Button>
    </Grid>
      </Grid>

</Paper>
</Grid>

</Grid>
)

}

export default Settings;


