import React,{useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/api";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ReferenceLine ,Bar,BarChart} from 'recharts';

import { TextField,Container,Grid,Paper,CardHeader, Button, CardActionArea, CardActions,Box,Card,CardMedia,Typography,CardContent,Avatar  } from "@mui/material";

import ThermostatIcon from '@mui/icons-material/Thermostat';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { SettingsApplicationsSharp } from "@mui/icons-material";
import moment from "moment";
import momentTimezone from "moment-timezone";
import "moment/locale/it";

var dBefore = new Date();
dBefore.setDate(dBefore.getDate() -7);
const PlcDettaglioRaschiatore = (props)=> {
const location = useLocation();
const [dataDa, setDa] = useState(moment(dBefore));
const [dataA, setA] = useState(moment(new Date()));
const [consumoMotore1,setConsumoMotore1] = useState(0);
const [consumoMotore2,setConsumoMotore2] = useState(0);
const [motoreTot1,setMotoreTot1] = useState([]);
const [motoreTot2,setMotoreTot2] = useState([]);

const [infoRaschiatore,setInfoRaschiatore] = useState([]);

const formatXAxis =(tickItem)=> {

    return tickItem===0?"":moment(new Date(tickItem.split(" ")[0])).format('DD/MM')
    }



const loadData = async(tipo,valore) =>{
  
        let da= tipo==="Da"?valore:dataDa;
        let a = tipo==="A"?valore:dataA;
      
      
          await api.get('dettaglioRaschiatore', {headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
          },params:{seriale:location.state.seriale,dataInizio:moment(da).format("YYYY-MM-DD"),dataFine:moment(a).format("YYYY-MM-DD"),raschiatore:location.state.zona}})
          .then( async res=> {
           
            setInfoRaschiatore(res.data.data.dettaglio);
            setConsumoMotore1(res.data.data.consumoMotore1);
            setConsumoMotore2(res.data.data.consumoMotore2);
            setMotoreTot1(res.data.data.motoreTot1);
            setMotoreTot2(res.data.data.motoreTot2);
          })
          
      } 


const handleButton = async (tipo) =>{
    let da = new Date ();
    let a = new Date();
switch(tipo){
case "Oggi": 

break;
case "Settimana": moment(da= da.setDate(da.getDate() -7));break;
case "Mese": da= new Date(da.setMonth(da.getMonth()-1)); break;
case "Anno": da= new Date(new Date().setFullYear(new Date().getFullYear() - 1));break;

}


setA(moment(a));
setDa(moment(da));
await api.get('dettaglioRaschiatore', {
    headers: {'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`},
    params:{seriale:location.state.seriale,dataInizio:moment(da).format("YYYY-MM-DD"),dataFine:moment(a).format("YYYY-MM-DD"),raschiatore:location.state.zona}})
   .then( async res=> {
    setInfoRaschiatore(res.data.data.dettaglio);
    setConsumoMotore1(res.data.data.consumoMotore1);
    setConsumoMotore2(res.data.data.consumoMotore2);
    setMotoreTot1(res.data.data.motoreTot1);
    setMotoreTot2(res.data.data.motoreTot2);
   })

}     

useEffect(() => {
    const fetchData = async () =>{ 
           
    await api.get('dettaglioRaschiatore', {
    headers: {'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`},
    params:{seriale:location.state.seriale,dataInizio:moment(dataDa).format("YYYY-MM-DD"),dataFine:moment(dataA).format("YYYY-MM-DD"),raschiatore:location.state.zona}})
   .then( async res=> {
    setInfoRaschiatore(res.data.data.dettaglio);
    setConsumoMotore1(res.data.data.consumoMotore1);
    setConsumoMotore2(res.data.data.consumoMotore2);
    setMotoreTot1(res.data.data.motoreTot1);
    setMotoreTot2(res.data.data.motoreTot2);
  
   })
   
 
 
 }
 
 fetchData()
 
 },[]);

    return(
    <Container style={{marginTop:"100px"}}>

          <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                      <Card sx={{ padding:"1px",marginBottom:"10px" }}>
                          <CardHeader title={location.state.title} subheader={location.state.subtitle} />
                          <Grid container spacing={0.5}>
                          <Grid item xs={12} md={6} >
                          <center><h3>Motore 1</h3>
                          consumo totale <input size={consumoMotore1.toString().length} defaultValue={consumoMotore1}/> KW
                          </center>
                              <ResponsiveContainer width="99%" height="99" aspect={window.innerWidth<800?1:2} >
                                <LineChart data={motoreTot1} margin={{top: 5,}}>
                                    <XAxis dataKey="name"
                                           interval={(motoreTot1.length/23)>19 && window.innerWidth<800 ?46:23}
                                           angle={90}   
                                           dy={20}
                                           height={70}
                                           tickFormatter={formatXAxis}
                                    />
                                    <YAxis  />
                                    <Tooltip  />
                                    <Line type="monotone" dataKey="kwh" stroke="#00a94e" dot={false} strokeWidth={2}  />
                                </LineChart>
                              </ResponsiveContainer>   
                        
                          </Grid>
                          <Grid item xs={12} md={6} >
                            <center><h3>Motore 2</h3>
                            consumo totale <input size={consumoMotore2.toString().length} defaultValue={consumoMotore2}/> KW
                            </center>
                              <ResponsiveContainer width="99%" height="99" aspect={window.innerWidth<800?1:2} >
                                <LineChart data={motoreTot2} margin={{top: 5,}}>
                                    <XAxis dataKey="name"
                                           interval={(motoreTot2.length/23)>19 && window.innerWidth<800 ?46:23}
                                           angle={90}   
                                           dy={20}
                                           height={70}
                                           tickFormatter={formatXAxis}
                                    />
                                    <YAxis  />
                                    <Tooltip  />
                                    <Line type="monotone" dataKey="kwh" stroke="#00a94e" dot={false} strokeWidth={2}  />
                                </LineChart>
                              </ResponsiveContainer>   
                          
                          </Grid>
                          <Grid item container xs={12} md={6}  justifyContent="center"  direction="row">
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}}  onClick={() =>handleButton("Oggi")}>Oggi</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Settimana")}>Settimana</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Mese")}>Mese</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Anno")}>Anno</Button>
    </Grid> 
                          <Grid item xs={6} md={3} style={{paddingTop:"15px"}}>

<LocalizationProvider dateAdapter={DateAdapter} >
      <DatePicker 
        label="Da:"
        value={dataDa}
        onChange={(newValue) => {
           setDa(newValue);
          loadData("Da",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   <Grid item xs={6} md={3} style={{paddingTop:"15px"}}>

<LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label="A:"
        minDate={dataDa}
        value={dataA}
        onChange={(newValue) => {
          setA(newValue);
          loadData("A",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

    </Grid>
   
    <Grid item xs={12} md={6} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",}}>
    <TextField
                fullWidth
                label="KW Motore 1"
                name="KW1"
                readOnly={true}
                value={infoRaschiatore.KW1?infoRaschiatore.KW1:""}
                variant="outlined"
              />
   </Grid>
  
   
   <Grid item xs={12} md={6} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",}}>
    <TextField 
               
               fullWidth
               label="KW Motore 2"
               name="KW2"
               readOnly
               value={infoRaschiatore.KW2?infoRaschiatore.KW2:""}
               variant="outlined"
             />
   </Grid>
   <Grid item xs={12} md={12} style={{paddingLeft:"15px",paddingRight:"15px",paddingTop:"10px",paddingBottom:"15px"}}>
   <TextField 
               
               fullWidth
               label="Note"
               name="Note"
               readOnly
               value={infoRaschiatore.Note?infoRaschiatore.Note:""}
               variant="outlined"
             />
   
   </Grid>
                          </Grid>
                      </Card>
                  </Grid>
              </Grid>
          </Box>
    </Container>
   
   )
}


export default PlcDettaglioRaschiatore;