import React from "react";
import { Button } from "@mui/material";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';



const LogExcel = (props) => {
        return (
     <>
<ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename={"Log"}
                    sheet={"Log"}
                    style={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}}
                    buttonText="Download Log"/>

<table id="table-to-xls" style={{marginTop:10,display:"None"}} cellSpacing={0} border={0}>
<tr><th>Data</th><th>Ora</th><th>THI</th><th>Temperatura</th><th>Umidità</th></tr>
{props.data?.map((el,index) => 
<tr key={index}><td>{el.Data.split(" ")[0]}</td><td>{el.Data.split(" ")[1]}</td><td>{Math.round(el.THI)}</td><td>{Math.round(el.TEMP)}</td><td>{Math.round(el.UM)}</td></tr>



)
}
</table>

     </>
        );
    
}

export default LogExcel;


//<Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}}>Download Log</Button>
