import react,{useState}  from 'react';
import api from '../api/api';
import {Dialog,DialogTitle,DialogContent,TextField,Typography,Button,Box,Grid} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DettaglioRaschiatori= (props) =>{
    const [open, setOpen] = useState(false);
    const [raschiatori,setRaschiatori] = useState([]);

    const handleOpen = async() => {
        await api.get('dettaglioRaschiatori', {headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
        },params:{seriale:props.seriale}})
        .then( res=> {
          setRaschiatori(res.data.data);
          
        })
        setOpen(true)
    
    }
const handleChange = (e,index) => {
let rTemp = [...raschiatori];
rTemp[index][e.target.name] = e.target.value;
setRaschiatori(rTemp);
}

const handleSave = async (index) => {
try{
    await api.put('dettaglioRaschiatori',{raschiatore:raschiatori[index]},
    { headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
    }}  )
    .then(res => {
        alert("Salvataggio effettuato con successo");
  
      })
}catch{

}

}


    const handleClose = () => setOpen(false);
    return (
        <div>
            <Button onClick={handleOpen} id={props.idButton} sx={{display:"None"}}  endIcon={<i class="bi bi-plus"></i>}>Aggiungi</Button>
            
          <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
              <DialogTitle>Dettaglio Raschiatori</DialogTitle>
              <DialogContent dividers={true}>
                <Grid container spacing={1}>        {
            raschiatori?.map((raschiatore,index)=> {
                return (
                <>
              <Grid item xs={12}><h3>Raschiatore {index+1}</h3> </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                onChange={(e) => handleChange(e,index)}
                fullWidth
                label="KW Motore 1"
                name="KW1"
                type="number"
                required
                value={raschiatore.KW1}
                variant="outlined"
              />
                </Grid>
                <Grid item xs={12} md={6}>
                <TextField
                onChange={(e) => handleChange(e,index)}
                fullWidth
                label="KW Motore 2"
                name="KW2"
                type="number"
                required
                value={raschiatore.KW2}
                variant="outlined"
              />
                </Grid>
                <Grid item xs={12} >
                <TextField
              onChange={(e) => handleChange(e,index)}
                fullWidth
                label="Note"
                name="Note"
                required
                multiline
                value={raschiatore.Note}
                variant="outlined"
              />
                </Grid>
                <Grid item xs={12}>
                <Button variant="contained" color="success" size="small" onClick={() => handleSave(index)}>Salva</Button>
                </Grid>
                </>
                )
           
            }
                
                )
        }
        </Grid>

            </DialogContent >
          </Dialog>
        </div>
      );

    
}


export default DettaglioRaschiatori;