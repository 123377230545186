import React,{useEffect,useState} from "react";
import api from "../api/api";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { TextField,Container,Grid,Paper,CardHeader, Button, CardActionArea, CardActions,Box,Card,CardMedia,Typography,CardContent,Avatar  } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ReferenceLine ,Bar,BarChart} from 'recharts';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { SettingsApplicationsSharp } from "@mui/icons-material";
import momentTimezone from "moment-timezone";
import "moment/locale/it";
import { useNavigate } from "react-router-dom";


var dBefore = new Date();
dBefore.setDate(dBefore.getDate() -1) //ricordati -7

const VentoAcqua = (props) => {
    const [dataDa, setDa] = useState(moment(dBefore));
    const [dataA, setA] = useState(moment(new Date()));
    const [dataAcqua,setDataAcqua] = useState([]);
    const [dataVento,setDataVento] = useState([]);
    const [consumoTotaleAcqua,setConsumoTotaleAcqua] = useState(0);
    const [consumoTotaleVento,setConsumoTotaleVento] = useState(0);
    const [totaleZone,setTotaleZone] = useState([]);
    const [totaleRaschiatori,setTotaleRaschiatori] = useState([]);
  const navigate = useNavigate();

    const formatXAxis =(tickItem)=> {
        // If using moment.js
      
        return tickItem===0?"":moment(new Date(tickItem.split(" ")[0])).format('DD/MM')
        }

        const handleButton = async (tipo) =>{
          let da = new Date ();
          let a = new Date();
            switch(tipo){
            case "Oggi": 
            
            break;
            case "Settimana": moment(da= da.setDate(da.getDate() -7));break;
            case "Mese": da= new Date(da.setMonth(da.getMonth()-1)); break;
            case "Anno": da= new Date(new Date().setFullYear(new Date().getFullYear() - 1));break;
            
            }
            
            
            setA(moment(a));
            setDa(moment(da));
            await api.get('plcVentoAcqua', {headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
          },params:{seriale:props.seriale,dataInizio:moment(da).format("YYYY-MM-DD"),dataFine:moment(a).format("YYYY-MM-DD")}})
          .then( async res=> {
            setDataVento(res.data.data.totaleKW);
            setTotaleZone(res.data.data.totaleZone);
            setTotaleRaschiatori(res.data.data.totaleRaschiatori);
            setDataAcqua(res.data.data.totaleAcqua);
            setConsumoTotaleAcqua(res.data.data.consumoTotaleAcqua);
            setConsumoTotaleVento(res.data.data.consumoTotaleVento);
          })
          
            
      }     
      
    useEffect(() => {

        const fetchData = async () =>{
         await api.get('plcVentoAcqua', {headers: {
           'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
       },params:{seriale:props.seriale,dataInizio:moment(dataDa).format("YYYY-MM-DD"),dataFine:moment(dataA).format("YYYY-MM-DD")}})
       .then( async res=> {
        setDataVento(res.data.data.totaleKW);
        setTotaleZone(res.data.data.totaleZone);
        setTotaleRaschiatori(res.data.data.totaleRaschiatori);
        setDataAcqua(res.data.data.totaleAcqua);
        setConsumoTotaleAcqua(res.data.data.consumoTotaleAcqua);
        setConsumoTotaleVento(res.data.data.consumoTotaleVento);
 
       })
       
     
     
     }
     
     fetchData()
     
     },[]);
    const loadData = async(tipo,valore) =>{
  
        let da= tipo==="Da"?valore:dataDa;
        let a = tipo==="A"?valore:dataA;
      
      
          await api.get('plcVentoAcqua', {headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
          },params:{seriale:props.seriale,dataInizio:moment(da).format("YYYY-MM-DD"),dataFine:moment(a).format("YYYY-MM-DD")}})
          .then( async res=> {
            setDataVento(res.data.data.totaleKW);
            setTotaleZone(res.data.data.totaleZone);
            setTotaleRaschiatori(res.data.data.totaleRaschiatori);
            setDataAcqua(res.data.data.totaleAcqua);
            setConsumoTotaleAcqua(res.data.data.consumoTotaleAcqua);
            setConsumoTotaleVento(res.data.data.consumoTotaleVento);
          })
          
      } 


      
      

return(<>
<Grid container spacing={0.5}>
<Grid item xs={12} md={3}>


<CardHeader
    title="Report Sostenibilità"

  >

 </CardHeader>     

   </Grid>
<Grid item xs={6} md={2.5} style={{paddingTop:"25px"}}>

<LocalizationProvider dateAdapter={DateAdapter} >
      <DatePicker 
        label="Da:"
        value={dataDa}
        onChange={(newValue) => {
           setDa(newValue);
          loadData("Da",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   <Grid item xs={6} md={2.5} style={{paddingTop:"25px"}}>

<LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        label="A:"
        minDate={dataDa}
        value={dataA}
        onChange={(newValue) => {
          setA(newValue);
          loadData("A",newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>

   </Grid>
   <Grid item  xs={12} md={4}  justifyContent="center"  direction="row">
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",marginTop:"30px",marginLeft:"10px",'&:hover':{backgroundColor:"green",color:"white"}}}  onClick={() =>handleButton("Oggi")}>Oggi</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",marginTop:"30px",marginLeft:"10px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Settimana")}>Settimana</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",marginTop:"30px",marginLeft:"10px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Mese")}>Mese</Button>
   <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",marginTop:"30px",marginLeft:"10px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={() =>handleButton("Anno")}>Anno</Button>
    </Grid>

<Grid item xs={12} md={6} container>
   <Grid item xs={12}>
   <center><h3>Energia Elettrica <br /></h3>
   consumo totale <input size={consumoTotaleVento?consumoTotaleVento.toString().length:0} value={consumoTotaleVento?consumoTotaleVento:0}/> KW
   </center>
<ResponsiveContainer width="99%" height="99" aspect={window.innerWidth<800?1:2} >
<LineChart data={dataVento} margin={{top: 5,}}>
      <XAxis 
        dataKey="name"
        angle={90} 
        dy={20}
        height={70}
        tickFormatter={formatXAxis}
        interval={(dataVento.length/23)>19 && window.innerWidth<800 ?46:23}
    />
      <YAxis />
      <Tooltip />
      <Legend  verticalAlign="top"/>
      <Line type="monotone" dataKey="kwh" stroke="#00a94e" dot={false} />

    </LineChart>
    </ResponsiveContainer>   
    
    </Grid>

    </Grid>
 
    <Grid item xs={12} md={6} >
    <center><h3>Consumo Acqua <br /></h3>
    consumo totale <input size={consumoTotaleAcqua?consumoTotaleAcqua.toString().length:0} value={consumoTotaleAcqua?consumoTotaleAcqua:0}/> L
    </center>
    <ResponsiveContainer width="99%" height="99" aspect={window.innerWidth<800?1:2} >
        <LineChart data={dataAcqua} margin={{top: 5,}}>
      <XAxis 
        dataKey="name"
        angle={90} 
        dy={20}
        height={70}
        tickFormatter={formatXAxis}
        interval={(dataVento.length/23)>19 && window.innerWidth<800 ?46:23}
    />
      <YAxis />
      <Tooltip />
      <Legend  verticalAlign="top"/>
      <Line type="monotone" dataKey="lh" stroke="#00a94e" dot={false} />

        </LineChart>
    </ResponsiveContainer>  
    </Grid>
     
    </Grid>

<div style={{display: 'flex',
  flexDirection: 'row',
  padding: 10,overflowX:"auto"}}>
{totaleZone?.map((z,index) => 

<Paper key={index} elevation={3} sx={{padding:"10px",marginLeft:"10px",marginRight:"10px"}} className="pZone" onClick={()=>navigate("/DettaglioZona",{state: {seriale:props.seriale,tipologia:"ventilazione",subtitle:z.etichetta,vento:z.vento,zona:(index+1),title:`Ventilazione ${(index +1)}`}})}>
  {"Ventilazione " + (index +1)} <br></br>
  <p>{z.etichetta}</p>
</Paper>
)}

{totaleRaschiatori?.map((raschiatore,index) => 
  <Paper key={index} elevation={3} sx={{padding:"10px",marginLeft:"10px",marginRight:"10px"}} className="pZone" onClick={()=>navigate("/PlcDettaglioRaschiatore",{state: {seriale:props.seriale,tipologia:"raschiatore",zona:(index+1),title:`Raschiatore ${(index +1)}`}})}>
  {"Raschiatore " + (index +1)} <br></br>

</Paper>

)
}


</div>




</>)



}

export default VentoAcqua;