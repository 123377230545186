import React,{useState} from "react";
import moment from "moment";
import { Avatar,Container,Grid,Paper,CardHeader, Button, CardActionArea, CardActions,Box,Card,CardMedia,Typography,CardContent,Tooltip,Modal,IconButton  } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import Gauge from 'react-svg-gauge'

let giornoSel=[];
let sopraSoglia=false;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  height: "70%",
  bgcolor: 'background.paper',
  borderRadius:"5px",
  overflow: 'auto',
  boxShadow: 24,
  p: 4,
};
const Meteo = (props) => {
  const [settings,setSettings] = useState(JSON.parse(sessionStorage.getItem("settings")));
 

const handleMeteoClick = (giorno,oreSopraSoglia) => {
giornoSel=giorno;
sopraSoglia=oreSopraSoglia>=5?true:false;

document.getElementById("modalMeteo").click();
}


return <>
<MeteoDetails></MeteoDetails>

            <Grid item xs={12} md={12} >
            <Carousel>
{props.meteo.length===0?
""
:

props.meteo.map((giorno,index) => {

  let totThiSopraSoglia = 0
  if(index<props.meteo.length-1){

  let thiOreNotturne = []
  for(let i=13;i<props.meteo[index].Temperature.length;i++){ // le ultime ore serali
    if(props.meteo[index].IsDayLight[i]===0){
    let thi = (Math.round((1.8 * props.meteo[index].Temperature[i] + 32) -((0.55-0.55 * (props.meteo[index].Relative_humidity[i]/100)) * (1.8 * props.meteo[index].Temperature[i]- 26)))*10)/10
    thiOreNotturne.push(thi)
    }
  }

  for(let i=0;i<13;i++){
    if(props.meteo[index+1].IsDayLight[i]===0){ //le prime ore della mattina
    let thi = (Math.round((1.8 * props.meteo[index+1].Temperature[i] + 32) -((0.55-0.55 * (props.meteo[index+1].Relative_humidity[i]/100)) * (1.8 * props.meteo[index+1].Temperature[i]- 26)))*10)/10
    thiOreNotturne.push(thi)
  }
  }
//primo calcolo numeri in generale maggiori di 6
//thiOreNotturne.map(t => t>settings.soglia3THI?totThiSopraSoglia+=1:"");
//calcolo consecutivi
let temp=0;

thiOreNotturne.map(t => {
  if(t>=settings.soglia3THI){
temp++;
if(temp >=5){
  totThiSopraSoglia= temp;

}
  }else{
    temp=0;
  }
}
);


}


  let dayOfTheWeek = new Date(giorno.giornoSingolo);
  let sel = new Date().getHours();
  let day=""
  switch (dayOfTheWeek.getDay()){case 0 : day="Domenica";break;case 1 : day="Lunedì";break;case 2 : day="Martedì";break;case 3 : day="Mercoledì";break;case 4 : day="Giovedì";break;case 5 : day="Venerdì";break;case 6 : day="Sabato";break;}

return (
<Grid item xs={12} md={12/7}>

{/*<Card sx={{backgroundColor:totThiSopraSoglia>=5?"red":(index===0?"#00a94e":"#fff"),color:totThiSopraSoglia>=5?"white":index===0?"#fff":"#000"}}>*/}
<Card sx={{backgroundColor:index===0?"#00a94e":"#fff",color:index===0?"#fff":"#000"}}>
    <CardActionArea sx={{fontSize:15}} onClick={() => handleMeteoClick(giorno,totThiSopraSoglia)}>
        <CardContent>
        <center>
        <Grid item xs={12}>
            {day} {dayOfTheWeek.getDate()}
      </Grid>
      <Grid item xs={12}>
      <Gauge
        value={giorno.thi[sel]}
        label={""}
        width={120}
        height={70}
        topLabelStyle={{ display: "none" }}
        minMaxLabelStyle={{display: "none"}}
        color={giorno.thi[sel]>=settings.soglia4THI?"#7b002c":(giorno.thi[sel]>=settings.soglia3THI?"red":(giorno.thi[sel]>=settings.soglia2THI?"#febe1e":"#00a94e"))}
        valueLabelStyle={{}}
        valueFormatter={number => `${number}`}
      />
</Grid>




<Grid item xs={12} sx={{marginBottom:"10px"}}>
MIN {giorno.thiMin}
</Grid>
<Grid item xs={12} sx={{marginBottom:"10px"}}>
MAX {giorno.thiMax}
</Grid>
</center>
         </CardContent>
         </CardActionArea>
    </Card>

</Grid>
)

})

}

</Carousel>
</Grid>





{props.meteo.length===0?
""
:

props.meteo.map((giorno,index) => {

  
  let totThiSopraSoglia = 0
  if(index<props.meteo.length-1){

  let thiOreNotturne = []
  for(let i=13;i<props.meteo[index].Temperature.length;i++){ // le ultime ore serali
    if(props.meteo[index].IsDayLight[i]===0){
    let thi = (Math.round((1.8 * props.meteo[index].Temperature[i] + 32) -((0.55-0.55 * (props.meteo[index].Relative_humidity[i]/100)) * (1.8 * props.meteo[index].Temperature[i]- 26)))*10)/10
    thiOreNotturne.push(thi)
    }
  }

  for(let i=0;i<13;i++){
    if(props.meteo[index+1].IsDayLight[i]===0){ //le prime ore della mattina
    let thi = (Math.round((1.8 * props.meteo[index+1].Temperature[i] + 32) -((0.55-0.55 * (props.meteo[index+1].Relative_humidity[i]/100)) * (1.8 * props.meteo[index+1].Temperature[i]- 26)))*10)/10
    thiOreNotturne.push(thi)
  }
  }
//primo calcolo numeri in generale maggiori di 5

//thiOreNotturne.map(t => t>settings.soglia3THI?totThiSopraSoglia+=1:"");

//calcolo consecutivi
let temp=0;
thiOreNotturne.map(t => {
  if(t>=settings.soglia3THI){
temp++;
if(temp >=5){
  totThiSopraSoglia = temp;
 
}
  }else{
    temp=0;
  }
}
);

}

  let dayOfTheWeek = new Date(giorno.giornoSingolo);
  let sel = new Date().getHours();
  let day=""
  switch (dayOfTheWeek.getDay()){case 0 : day="Domenica";break;case 1 : day="Lunedì";break;case 2 : day="Martedì";break;case 3 : day="Mercoledì";break;case 4 : day="Giovedì";break;case 5 : day="Venerdì";break;case 6 : day="Sabato";break;}

return (
<Grid item xs={12} md={12/7} className="mobileCardMeteo" >
    {/*<Card sx={{backgroundColor:totThiSopraSoglia>=5?"red":(index===0?"#00a94e":"#fff"),color:totThiSopraSoglia>=5?"white":index===0?"#fff":"#000"}}>*/}
    <Card sx={{backgroundColor:index===0?"#00a94e":"#fff",color:index===0?"#fff":"#000"}}>
    <CardActionArea sx={{fontSize:15}} onClick={() => handleMeteoClick(giorno,totThiSopraSoglia)}>
        <CardContent>
    
        <center>
        <Grid item xs={12}>
            {day} {dayOfTheWeek.getDate()} { totThiSopraSoglia>=6?<Tooltip title="Attenzione nelle prossime 6 ore è prevista un'ondata di calore"><i class="bi bi-exclamation-triangle-fill" style={{color:"white"}}></i></Tooltip>:""}
      </Grid>
      <Grid item xs={12}>
        THI
      <Gauge
        value={giorno.thi[sel]}
        label={""}
        width={120}
        height={70}
        topLabelStyle={{ display: "none" }}
        minMaxLabelStyle={{display: "none"}}
        color={giorno.thi[sel]>=settings.soglia4THI?"#7b002c":(giorno.thi[sel]>=settings.soglia3THI?"red":(giorno.thi[sel]>=settings.soglia2THI?"#febe1e":"#00a94e"))}
        valueLabelStyle={{}}
        valueFormatter={number => `${number}`}
      />
      <Grid item xs={12} sx={{marginBottom:"10px"}}>
MIN {giorno.thiMin}
</Grid>
<Grid item xs={12} sx={{marginBottom:"10px"}}>
MAX {giorno.thiMax}
</Grid>
</Grid>



</center>

         </CardContent>
         </CardActionArea>
    </Card>

</Grid>
)

})

}



</>
}


export default Meteo;





const MeteoDetails = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [settings,setSettings] = useState(JSON.parse(sessionStorage.getItem("settings")));
  return (
    <div>
      <button type="button" style={{display:"None"}} onClick={handleOpen} id="modalMeteo">
        Open modal
      </button>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
     
      >
        <Box sx={style}>
          <h2 id="unstyled-modal-title">Meteo {moment(giornoSel.giornoSingolo).format("DD-MM-YYYY")}  {sopraSoglia?<Tooltip enterTouchDelay={0} title="Attenzione! prevista ondata di calore notturna">
          <IconButton aria-label="settings">
           <i class="bi bi-exclamation-triangle-fill" style={{color:"red"}}></i>
          </IconButton></Tooltip>:""}</h2>
         
          <Grid container spacing={3}  alignItems="center">
         {
           giornoSel.Giorni?.map((giorno,index)=>{
             let thi = (Math.round((1.8 * giornoSel.Temperature[index] + 32) -((0.55-0.55 * (giornoSel.Relative_humidity[index]/100)) * (1.8 * giornoSel.Temperature[index]- 26)))*10)/10
             return(<Grid item xs={12} md={6}>
                {giorno.split(" ")[1]}
               <Paper sx={{padding:1}}>
               <Grid container spacing={3} alignItems="center">
               <Grid item>
              
               <Avatar alt="" src={giornoSel.IsDayLight[index]===0?require("../img/meteo/"+giornoSel.Pitcode[index]+"_night.png"):require("../img/meteo/"+giornoSel.Pitcode[index]+"_day.png")} />
                 </Grid> 
                   <Grid item xs={9} container spacing={2} alignItems="center">
                 <Grid item style={{fontSize:18}} xs={6} md={3}>
                 <Avatar sx={{ width: 70, height: 70 }} className={Math.round(giornoSel.Temperature[index])>=settings.soglia4TEMP?"soglia4":(Math.round(giornoSel.Temperature[index])>=settings.soglia3TEMP?"soglia3":(Math.round(giornoSel.Temperature[index])>=settings.soglia2TEMP?"soglia2":"soglia1"))}>
                 {Math.round(giornoSel.Temperature[index])}°C<i class="bi bi-thermometer-half"></i>
                 </Avatar>
                 </Grid>
                 <Grid item style={{fontSize:18}} xs={6} md={3}>
                 <Avatar sx={{ width: 70, height: 70 }} className={giornoSel.Relative_humidity[index]>=settings.soglia4UM?"soglia4":(giornoSel.Relative_humidity[index]>=settings.soglia3UM?"soglia3":(giornoSel.Relative_humidity[index]>=settings.soglia2UM?"soglia2":"soglia1"))}>
                {giornoSel.Relative_humidity[index]}  <i class="bi bi-droplet-half"></i>
                </Avatar>
                 </Grid>
              
                 <Grid item style={{fontSize:18}} xs={6} md={3}>
                 <Avatar sx={{ width: 70, height: 70,backgroundColor:"#00a94e",color:"white" }}>
                {giornoSel.Precipitation_probability[index]}   <i class="bi bi-cloud-drizzle" style={{marginLeft:"2px"}}></i>
                </Avatar>
                 </Grid>
                 <Grid item style={{fontSize:18}} xs={6} md={3}>
                 <Avatar sx={{ width: 70, height: 70 }} className={thi>=settings.soglia4THI?"soglia4":(thi>=settings.soglia3THI?"soglia3":(thi>=settings.soglia2THI?"soglia2":"soglia1"))}>
                {thi}  <span className="iconify" data-icon="mdi:cow"></span>
                </Avatar>
                
                 </Grid>
                 </Grid> 
                 </Grid>
                 </Paper>
            
            </Grid>)

           })
         }
         </Grid>
        </Box>
      </Modal>
    </div>
  );


}





//domenica è = 0

//https://content.meteoblue.com/en/specifications/standards/symbols-and-pictograms <- immagini api meteoblu