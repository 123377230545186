import React,{useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/api";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,ReferenceLine } from 'recharts';

import { Backdrop,SpeedDial,SpeedDialIcon,SpeedDialAction,Avatar,Container,Grid,Paper,CardHeader, Button, CardActionArea, CardActions,Box,Card,CardMedia,Typography,CardContent,IconButton  } from "@mui/material";
import ToolTipMui from "@mui/material/Tooltip";
import Analysis from "./Analysis";
import "slick-carousel/slick/slick.css";
import moment from "moment";
import "slick-carousel/slick/slick-theme.css";
import Meteo from "./Meteo";
import VentoAcqua from "./VentoAcqua";
import { useCookies } from "react-cookie";
import { AccessTime, Air, Sensors, Stop, Water } from "@mui/icons-material";







var dBefore = new Date();
dBefore.setDate(dBefore.getDate() -7)


const PlcData = () =>{
  const [open, setOpen] = React.useState(false);
const [cookies, setCookie] = useCookies(["meteo"]);
const location = useLocation();
const navigate = useNavigate();
const [plcData,setPlcData]=useState([]);
const [tipo,setTipo]=useState("THI");
const [plcDataAnalysis,setPlcDataAnalysis]=useState([]);
const [meteo,setMeteo]=useState([]);
const [ondaDiCalore,setOndaDiCalore]=useState(false)
const [differenceMeteo,setDifferenceMeteo]=useState(0);
const [differenceTHI,setDifferenceTHI]=useState(0); 
const [differenceUmidita,setDifferenceUmidita]=useState(0);
const [settings,setSettings] = useState({soglia1THI:0,soglia2THI:0,soglia3THI:0,soglia4THI:0,
  soglia1TEMP:0,soglia2TEMP:0,soglia3TEMP:0,soglia4TEMP:0,
  soglia1UM:0,soglia2UM:0,soglia3UM:0,soglia4UM:0});

  const actions = [
    {icon:<Air/>,name:"Cooling Aria",operazione:"Aria"},
    {icon:<Water/>,name:"Cooling Aria Acqua",operazione:"AriaAcqua"},
    {icon:<Stop/>,name:"Stop Cooling",operazione:"Stop"},
    {icon:<AccessTime/>,name:"Imposta Data",operazione:"Time"},
  ]
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


 
    useEffect(() => {
 
        const fetchData = async () =>{
    let plcTempo = await api.get('plcData', {headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
        },params:{seriale:location.state.seriale}})
         
        let plcDataTemp =[];
      
          if(plcTempo.data.data.length > 0){
           plcDataTemp=plcTempo.data.data[0];
          plcDataTemp.Vento =plcDataTemp?.Vento?.split(",");
          plcDataTemp.Acqua =plcDataTemp?.Acqua?.split(",");
          await setPlcData(plcDataTemp)
          }
        //DATA ANALYSIS
       await api.get('plcDataAnalysis', {headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
      },params:{seriale:location.state.seriale,dataInizio:moment(dBefore).format("YYYY-MM-DD"),dataFine:moment(new Date()).format("YYYY-MM-DD")}})
      .then(async res=> {
       await setPlcDataAnalysis(res.data.data.all)
      })

      let datiMeteo="";
      let date = localStorage.getItem("dataMeteo");
      let confDate = new Date();
      confDate.setHours(0,0,0,0);
   

      if(JSON.parse(localStorage.getItem("utente")).auth!==1 && date!== null && new Date(confDate).getTime() === new Date(date).getTime()){

        datiMeteo=[JSON.parse(localStorage.getItem('meteo1')),JSON.parse(localStorage.getItem('meteo2')),JSON.parse(localStorage.getItem('meteo3')),JSON.parse(localStorage.getItem('meteo4')),JSON.parse(localStorage.getItem('meteo5')),JSON.parse(localStorage.getItem('meteo6')),JSON.parse(localStorage.getItem('meteo7'))]

        setMeteo(datiMeteo);
      }else{
        console.log("Pino")
        const meteoResponse = await api.post('meteo',{operazione:"meteo",lon:location.state.lon,lat:location.state.lat},
        { headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
        }}  )
        setMeteo(meteoResponse.data);
        datiMeteo=meteoResponse.data;
        let expDate = new Date();
        expDate.setHours(0,0,0,0);
        localStorage.setItem("dataMeteo",expDate);   
        localStorage.setItem("meteo1",JSON.stringify(meteoResponse.data[0]));
        localStorage.setItem("meteo2",JSON.stringify(meteoResponse.data[1]));
        localStorage.setItem("meteo3",JSON.stringify(meteoResponse.data[2]));
        localStorage.setItem("meteo4",JSON.stringify(meteoResponse.data[3]));
        localStorage.setItem("meteo5",JSON.stringify(meteoResponse.data[4]));
        localStorage.setItem("meteo6",JSON.stringify(meteoResponse.data[5]));
        localStorage.setItem("meteo7",JSON.stringify(meteoResponse.data[6]));

      /*setCookie("meteo1",meteoResponse.data[0], {
        path: "/",expires:expDate
      });
      setCookie("meteo2",meteoResponse.data[1], {
        path: "/",expires:expDate
      });
      setCookie("meteo3",meteoResponse.data[2], {
        path: "/",expires:expDate
      });
      setCookie("meteo4",meteoResponse.data[3], {
        path: "/",expires:expDate
      });
      setCookie("meteo5",meteoResponse.data[4], {
        path: "/",expires:expDate
      });
      setCookie("meteo6",meteoResponse.data[5], {
        path: "/",expires:expDate
      });
      setCookie("meteo7",meteoResponse.data[6], {
        path: "/",expires:expDate
      });*/

  
      }
     

      let ora = new Date().getHours();
      console.log(plcDataTemp)
      setDifferenceMeteo(Math.round(parseFloat(plcDataTemp.Temperatura))-Math.round(datiMeteo[0].Temperature[ora]));
      setDifferenceTHI(Math.round(parseFloat(plcDataTemp.THI))-Math.round(datiMeteo[0].thi[ora]));
      setDifferenceUmidita(Math.round(parseFloat(plcDataTemp.Umidita))-Math.round(datiMeteo[0].Relative_humidity[ora]));
      setSettings(JSON.parse(sessionStorage.getItem("settings")));

      let tempAlert = datiMeteo[0].Temperature.concat(datiMeteo[1].Temperature) //unisco il giorno corrente con il successivo temperatura
      let humAlert = datiMeteo[0].Relative_humidity.concat(datiMeteo[1].Relative_humidity) //unisco il giorno corrente con il successivo umidità
      let thiAlert =[];
      /*for(let i=0;i<6;i++){
        //thiAlert.push((Math.round((1.8 * tempAlert[ora+i] + 32) -((0.55-0.55 * (humAlert[ora+i]/100)) * (1.8 * tempAlert[ora+i] - 26)))*10)/10);
      thiAlert.push(70);
      }
      
     setOndaDiCalore(thiAlert.every(value => value >68)) //ondata di calore
*/


    }

fetchData()

    },[]);


const eseguiOperazione = async(operazione)=>{
try{
  
    let minuti = 0;

    if(operazione ==="Aria" || operazione ==="AriaAcqua"){

      minuti = window.prompt("Inserisci numero di minuti:")
      while (!/^[0-9]+$/.test(minuti) || minuti <=0 || minuti >999) { // se non è un numer
        alert("ATTENZIONE, devi inserire un numero di minuti valido compreso tra 1  e massimo 999");
        minuti = prompt("Inserisci numero di minuti:");
    }



    
    }


  let op = {Minuti:minuti,Operazione:operazione,Seriale:location.state.seriale,Eseguita:0,Data:moment(new Date()).format("YYYY-MM-DD")};
  let responseCliente = await api.post('operazioni',{operazione:op},
  { headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
  }}  )
  
alert("OK")

}catch{

alert("ERRORE")
}

}




    return(

<>

<Container style={{marginTop:"100px"}}>

    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
    <Backdrop open={open} sx={{zIndex:50}} />
    <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute ',left:0,right:0, '& .MuiFab-primary:hover': { backgroundColor: 'green' }, '& .MuiFab-primary': { backgroundColor: 'green' }}}
        icon={<SpeedDialIcon icon={<Sensors />} />  }
        direction="down"
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={()=>eseguiOperazione(action.operazione)}
            //tooltipOpen
          />
        ))}
      </SpeedDial>
      <Backdrop/>
      <br/>
    <br/>
      </Grid>
    
    <Grid item xs={12} md={12}>
      <h3 style={{marginBottom:"-10px"}}>Dati Meteo</h3> 
      </Grid>
      <Meteo meteo={meteo}></Meteo>
      <Grid item xs={12} md={12}>
      <h3 style={{marginTop:"-5px",marginBottom:"-8px"}}>Dati Stalla</h3> 
      </Grid>
      
<Grid item xs={12} md={4}>

<Card className={Number(plcData.Temperatura)>=Number(settings.soglia4TEMP)?"soglia4":(Number(plcData.Temperatura)>=Number(settings.soglia3TEMP)?"soglia3":Number(plcData.Temperatura)>=Number(settings.soglia2TEMP)?"soglia2":"soglia1")}>
  <CardActionArea onClick={() => setTipo("TEMP")}>
<CardHeader
        avatar={
          <Avatar aria-label="recipe"  className="iconPlcData">
          <i className="bi bi-thermometer-half"></i>
          </Avatar>
        }
        subheaderTypographyProps={{variant:'h5' }}
        titleTypographyProps={{variant:'h5' }}
        title="Temperatura"
        subheader={Math.round(parseFloat(plcData.Temperatura))+" °C ( "+(differenceMeteo>0?"+ ":"") +differenceMeteo +"°C )"
        }
      />
     
     </CardActionArea>    
    </Card>
   
    </Grid>
    <Grid item xs={12} md={4}>

<Card className={plcData.THI>=settings.soglia4THI?"soglia4":(plcData.THI>=settings.soglia3THI?"soglia3":(plcData.THI>=settings.soglia2THI?"soglia2":"soglia1"))}>
<CardActionArea onClick={() => setTipo("THI")}>
<CardHeader style={{color:"white!important"}}
        avatar={
          <Avatar aria-label="recipe" className="iconPlcData">
       <span className="iconify" data-icon="mdi:cow" style={{width:"24px",height:"24px"}}></span>
          </Avatar>
        }
       
        title="THI"
        subheader={Math.round(parseFloat(plcData.THI))+" ( "+(differenceTHI>0?"+ ":"") +differenceTHI +" )"}
        titleTypographyProps={{variant:'h5' }}
        subheaderTypographyProps={{variant:'h5' }}
        action={ondaDiCalore?(
          <ToolTipMui title="Attenzione nelle prossime 6 ore è prevista un'ondata di calore">
          <IconButton aria-label="settings">
           <i class="bi bi-exclamation-triangle-fill" style={{color:"#890404"}}></i>
          </IconButton></ToolTipMui>):("")

        }

      />
     
     </CardActionArea>   
    </Card>

    </Grid>
    <Grid item xs={12} md={4}>

    <Card className={plcData.Umidita>=settings.soglia4UM?"soglia4":(plcData.Umidita>=settings.soglia3UM?"soglia3":(plcData.Umidita>=settings.soglia2UM?"soglia2":"soglia1"))}>
<CardActionArea onClick={() => setTipo("TEMP")}>
<CardHeader
        avatar={
          <Avatar aria-label="recipe" className="iconPlcData">
    <i className="bi bi-droplet-half"></i>
          </Avatar>
        }
        titleTypographyProps={{variant:'h5' }}
        title="Umidità"
        subheader={Math.round(parseFloat(plcData.Umidita))+" ( "+(differenceUmidita>0?"+ ":"") +differenceUmidita +" )"}
        subheaderTypographyProps={{variant:'h5' }}
      />
     
     </CardActionArea>
    </Card>

    </Grid>

 
    <Grid item xs={12} md={12}>

<Card sx={{ padding:"1px",marginBottom:"10px" }}>
 
    <VentoAcqua seriale={location.state.seriale}/>
    </Card>

    </Grid>

    <Grid item xs={12} md={12}>

<Card sx={{ padding:"1px",marginBottom:"10px" }}>
 
    <Analysis plcDataAnalysis={plcDataAnalysis} tipo={tipo} seriale={location.state.seriale}/>
    </Card>

    </Grid>


      
        
      </Grid>
      
</Box>




</Container>
</>


    )

}

export default PlcData;