import React,{useEffect,useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api/api";
import { Avatar,Divider,Container,Grid,Paper,CardHeader, Button,Snackbar,Alert, CardActionArea, CardActions,Box,Card,CardMedia,Typography,CardContent,TextField  } from "@mui/material";
import { Map, Marker } from "pigeon-maps";
import { DataGrid } from '@mui/x-data-grid';
import Modal from "./Modal";
import DettaglioZone from "./DettaglioZone";
import DettaglioRaschiatori from "./DettaglioRaschiatori";


const DettaglioCliente = () => {
const location = useLocation();
const navigate = useNavigate();
const [cliente,setCliente] =useState({ID:"",RagioneSociale:"",Email:"",Lon:"",Lat:"",Logo:"",Deleted:0})
const [utente,setUtente] =useState({Username:"",Password:"",Foto:"",Autorizzazione:2,Deleted:0,Cliente:0})
const [validation,setValidation]=useState({RagioneSociale:false,Lon:false,Lat:false,Username:false});
const [plc,setPlc] = useState([]);
const [seriale,setSeriale] = useState("");
const [descrizione,setDescrizione] = useState("");
const [zone,setZone] = useState("");
const [raschiatori,setRaschiatori] = useState("");
const [delta,setDelta] = useState("");
const [open, setOpen] = useState(false);
const [snack,setSnack] = useState({severity:"success",messaggio:"Salvataggio Effettuato correttamente"});




const columns = [
  { field: 'Seriale',minWidth:150 },{ field: 'Descrizione',minWidth:150 },{ field: 'Zone',minWidth:80 },{ field: 'Raschiatori',minWidth:80 },{ field: 'Delta',minWidth:80 },
  {field: 'action',headerName: "",minWidth:600,renderCell: (params) => (
    <>
    <strong>
   
    <Button
        variant="contained"
        color="success"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={async () => {await setSeriale(params.row.Seriale);await setDelta(params.row.Delta);await setRaschiatori(params.row.Raschiatori);await setDescrizione(params.row.Descrizione);await setZone(params.row.Zone);document.getElementById("ModalModifica").click()}}
      >
        Modifica
      </Button>
      <Button
        variant="contained"
        color="success"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={async () => {await setSeriale(params.row.Seriale);document.getElementById("ModificaZone").click()}}
      >
        Dettaglio Zone
      </Button>
      <Button
        variant="contained"
        color="success"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={async () => {await setSeriale(params.row.Seriale);document.getElementById("ModificaRaschiatori").click()}}
      >
        Dettaglio Raschiatori
      </Button>
      <Button
        variant="contained"
        color="error"
        size="small"
        style={{ marginLeft: 16 }}
        onClick={() => deletePlc(params.id)}
      >
        Elimina
      </Button>
      
    </strong>
      
       </>
    )},
];


const saveCliente = async(e) => {

    let check =false;
    let val = {...validation}
   if(cliente.RagioneSociale===""|| cliente.RagioneSociale===null){
     val.RagioneSociale = true;
     check=true;
   }else{
    val.RagioneSociale = false;
  }
  
   if(cliente.Lon==="" || cliente.Lon===null){
    val.Lon = true;
    check=true;
  }else{
    val.Lon = false;
  }
  if(cliente.Lat==="" || cliente.Lat===null){
    check=true;
    val.Lat = true;
  }else{
    
    val.Lat = false;
  }

  if(utente.Username===""|| utente.Username===null){
    val.Username = true;
    check=true;
  }else{
   val.Username = false;
 }
 
setValidation(val);
   if (check){

    let snackOption={ ...snack};
    snackOption.severity="error";
    snackOption.messaggio="Controllare i campi obbligatori";
 
    setSnack(snackOption);
    setOpen(true);



     return ;
   }




    try {




        if(location.state.operazione==="Modifica"){
          
        await api.put('clienti',{cliente:cliente},
        { headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
        }}  )
        .then(res => {
          
      
          })
          await api.put('utentiWebApp',{user:utente},
          { headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
          }}  )
          .then(res => {
      
        
            })




          let snackOption={ ...snack};
          snackOption.severity="success";
          snackOption.messaggio="Salvataggio Effettuato correttamente";
          snackOption.action="";
          setSnack(snackOption);
          setOpen(true);
        }else {

            let responseCliente = await api.post('clienti',{cliente:cliente},
            { headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
            }}  )
            
     
                let cli={ ...cliente};
                cli.ID=responseCliente.data.message.insertId;
             
                setCliente(cli);
                let ut = {...utente};
                ut.Cliente = responseCliente.data.message.insertId;
       
                setUtente(ut)
                location.state.operazione="Modifica";
             

             
              await api.post('utentiWebApp',{user:ut},
              { headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
              }}  )
              .then(res => {
                 
            
                })
              let snackOption={ ...snack};
              snackOption.severity="success";
              snackOption.messaggio="Creazione Avvenuta con successo";

              setSnack(snackOption);
              setOpen(true);

        }
        } catch{
            let snackOption={ ...snack};
            snackOption.severity="error";
            snackOption.messaggio="Errore nel salvataggio";
         
            setSnack(snackOption);
            setOpen(true);
        }
}

const deletePlc = async(seriale) =>{

  try{
    await api
    .delete('plc',
    { headers: {
      'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
    },
      data: {
        seriale: seriale
    }
  });

  await api
  .delete('dettaglioZone',
  { headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
  },
    data: {
      zone:0,
      seriale: seriale,
  }
});

  await api.get('plc', {headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
},params:{id:location.state.cliente.ID}})
.then( res=> {
    setPlc(res.data.data);
})


let snackOption={ ...snack};
snackOption.severity="success";
snackOption.messaggio="Plc cancellato con successo";

setSnack(snackOption);
setOpen(true);

}catch{
let snackOption={ ...snack};
snackOption.severity="error";
snackOption.messaggio="Errore nella cancellazione del cliente.";

setSnack(snackOption);
setOpen(true);

}





}

const deleteCliente = async() => {

    try{
        let clienteMod = cliente;
        clienteMod.Deleted=1;


      await api.put('clienti',{cliente:clienteMod},
      { headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
      }}  )
      .then(res => {
 
    
        })

        let utenteMod = utente;
        utenteMod.Deleted=1;


      await api.put('utentiWebApp',{user:utenteMod},
      { headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
      }}  )
      .then(res => {
        
    
        })
      

  let snackOption={ ...snack};
  snackOption.severity="success";
  snackOption.messaggio="Cliente cancellato con successo";
 
  setSnack(snackOption);
  setOpen(true);

setTimeout(() =>{
  navigate(-1); },1000)
}catch{
    let snackOption={ ...snack};
    snackOption.severity="error";
    snackOption.messaggio="Errore nella cancellazione del cliente.";
 
    setSnack(snackOption);
    setOpen(true);

}

 


}

const handleSaveModal = async (seriale,descrizione,zone,raschiatori,delta,operazione,zoneBackup,raschiatoriBackup) =>{
try{
   if(operazione==="Inserimento"){
   let plcNew={Seriale:seriale,Descrizione:descrizione,Zone:zone,Raschiatori:raschiatori,Delta:delta,Cliente:cliente.ID};
   
   await api.post('plc',{plc:plcNew},
   { headers: {
     'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
   }}  )
   await api.post('dettaglioZone',{zone:zone,seriale:seriale,zonePartenza:0},
   { headers: {
     'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
   }}  )
   await api.post('dettaglioRaschiatori',{raschiatori:raschiatori,seriale:seriale,raschiatoriPartenza:0},
   { headers: {
     'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
   }}  )

   
   let elencoPlc=[...plc];

   elencoPlc.push(plcNew);
   setPlc(elencoPlc);
   
   let snackOption={ ...snack};
   snackOption.severity="success";
   snackOption.messaggio="Plc inserito con successo";

   setSnack(snackOption);
   setOpen(true);
}
if(operazione==="Modifica"){
  let plcNew={Seriale:seriale,Descrizione:descrizione,Raschiatori:raschiatori,Zone:zone,Delta:delta,Cliente:cliente.ID};
  await api.put('plc',{plc:plcNew},
  { headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
  }}  )

//Zone
if(zone - zoneBackup> 0){ //per calcolare le zone di differenza con le già esistenti
  await api.post('dettaglioZone',{zone:zone,seriale:seriale,zonePartenza:zoneBackup},
  { headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
  }}  )
}
if(zone - zoneBackup< 0){
  await api
  .delete('dettaglioZone',
  { headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
  },
    data: {
      zone:zone,
      seriale: seriale, 
  }
});
}

//Raschiatori

if(raschiatori - raschiatoriBackup> 0){ //per calcolare le zone di differenza con le già esistenti

  await api.post('dettaglioRaschiatori',{raschiatori:raschiatori,seriale:seriale,raschiatoriPartenza:raschiatoriBackup},
  { headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
  }}  )
}
if(raschiatori - raschiatoriBackup< 0){
  await api
  .delete('dettaglioRaschiatori',
  { headers: {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}` 
  },
    data: {
      raschiatori:raschiatori,
      seriale: seriale, 
  }
});
}
  
  let elencoPlc=[...plc];

  var foundIndex = elencoPlc.findIndex(x => x.Seriale == seriale);
  elencoPlc[foundIndex].Descrizione = descrizione;
  elencoPlc[foundIndex].Zone = zone;
  elencoPlc[foundIndex].Delta = delta;
  elencoPlc[foundIndex].Raschiatori = raschiatori;
  setPlc(elencoPlc);

  let snackOption={ ...snack};
  snackOption.severity="success";
  snackOption.messaggio="Plc modificato con successo";

  setSnack(snackOption);
  setOpen(true);
  

}




}catch{
  let snackOption={ ...snack};
  snackOption.severity="error";
  snackOption.messaggio="Errore durante la creazione";

  setSnack(snackOption);
  setOpen(true);


}
}

const HandleChange = (e) => {

    let cli={ ...cliente};
    cli[e.target.name]=e.target.value;
    setCliente(cli);

}

const HandleChangeUser = (e) => {

  let us={ ...utente};
  us[e.target.name]=e.target.value;
  setUtente(us);

}



const handleChangeImage =async (e) => {
    const file = e.target.files[0] // prendo la foto caricata sempre nell'evento
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
     

      let cli={ ...cliente};
      cli.Logo=reader.result;
      setCliente(cli);

  
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  
  
  }

const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
}

useEffect(() => {
    const fetchData = async () =>{


      if(location.state.operazione==="Modifica"){
         
        setCliente(location.state.cliente)

        await api.get('utentiWebApp', {headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
      },params:{id:location.state.cliente.ID}})
      .then( res=> {
        let us =res.data.data[0];
        us.Password="";
        setUtente(us);



      })

        await api.get('plc', {headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
        },params:{id:location.state.cliente.ID}})
        .then( res=> {
           
        setPlc(res.data.data);


        })




      }


}

fetchData()

},[]);







return(
<div>

<input type='file' id='file' accept="image/*" onChange={(e) => handleChangeImage(e)} style={{display: 'none'}}/>
<Container style={{marginTop:"100px"}}>
<Snackbar open={open} autoHideDuration={6000} style={{marginTop:"50px"}} anchorOrigin={{vertical:"top",horizontal: "right" }} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snack.severity} sx={{ width: '100%' }}>
     {snack.messaggio}
        </Alert>
      </Snackbar>



<Grid container spacing={3}>
          <Grid
            item
            lg={4}
            md={6}
            xs={12}>
<Card>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
          <CardActionArea   onClick={() => {document.getElementById("file").click()}} >
         <CardMedia
          component="img"
          height="140"
          image={cliente.Logo!==""?(cliente.Logo):("")}
          src={cliente.Logo!==""?cliente.Logo:require("../img/addPicture.png")}
       
          alt="img"
        />
     </CardActionArea >
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      <Button
        color="success"
        fullWidth
        variant="text"
   onClick={() => {document.getElementById("file").click()}} 

      >
        Carica Foto
      </Button>
    </CardActions>
  </Card>
  
  {cliente.ID!==""?(
  <Card style={{marginTop:"20px"}}>
  <CardContent>
    {/*<Button onClick={() => window.open("https://maps.google.com/?q=" + cliente.Lat + "," + cliente.Lon)}>apri google maps</Button>*/}
  <Map height={300} center={[Number(cliente.Lat),Number(cliente.Lon)]} zoom={13} mouseEvents={false} touchEvents={false}>
      <Marker width={50} anchor={[Number(cliente.Lat), Number(cliente.Lon)]} />
    </Map>
</CardContent>
      </Card>):("")}
       
          </Grid>

{/* INFORMAZIONI Cliente */}



          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
               <form
      autoComplete="off"
      noValidate

    >
      <Card>
        <CardHeader
          title="Anagrafica"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
              error={validation.RagioneSociale}
                fullWidth
               onChange={(e) => HandleChange(e)}
                label="Ragione Sociale"
                name="RagioneSociale"
                required
                value={cliente.RagioneSociale}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
              
                fullWidth
                label="Email"
                name="Email"
                onChange={(e) => HandleChange(e)}
                value={cliente.Email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
              error={validation.Lat}
                fullWidth
                label="Latitudine"
                onChange={(e) => HandleChange(e)}
                name="Lat"
                required
                value={cliente.Lat}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={validation.Lon}
                fullWidth
                onChange={(e) => HandleChange(e)}
                label="Longitudine"
                name="Lon"
                required
                value={cliente.Lon}
                variant="outlined"
              />
            </Grid>
            
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={validation.Username}
                fullWidth
                onChange={(e) => HandleChangeUser(e)}
                label="Utente"
                name="Username"
                required
                value={utente.Username}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
           
                fullWidth
                label="Password"
                onChange={(e) => HandleChangeUser(e)}
                name="Password"
                value={utente.Password}
                variant="outlined"
                type="password"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
         <Button variant="contained" sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}} onClick={()=> saveCliente()} endIcon={<i class="bi bi-check"></i>}>Salva</Button>
         {cliente.ID!==""?(
         <Button variant="contained" color="error" onClick={()=> deleteCliente()} endIcon={<i class="bi bi-trash"></i>}>Elimina</Button>):("")}
    
      </Card>
    </form>

{cliente.ID!==""?(
    <Card style={{marginTop:"20px"}}>
    <CardHeader
          title="PLC"
        />
        <Divider />
        <CardContent>
        <DettaglioRaschiatori  seriale={seriale} idButton="ModificaRaschiatori" operazione="Modifica" />
        <DettaglioZone  seriale={seriale} idButton="ModificaZone" operazione="Modifica" />
          <Modal seriale="" descrizione="" zone="" delta="" raschiatori="" func={handleSaveModal} operazione="Inserimento"/>      <Modal seriale={seriale} delta={delta} zone={zone} zoneBackup={zone} raschiatori={raschiatori} raschiatoriBackup={raschiatori} idButton="ModalModifica" descrizione={descrizione} func={handleSaveModal} operazione="Modifica" />
        <Box sx={{ height: 300, bgcolor: 'background.paper' }} >
        <DataGrid disableSelectionOnClick rows={plc} columns={columns} getRowId={plc => plc.Seriale} />
      </Box>

        </CardContent>
</Card>):("")

        }
    
          </Grid>





        </Grid>
    
   
    </Container>
    
    </div>

)    
    

}

export default DettaglioCliente