import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const pages = ["Clienti"];
const pagesUser = ["Home"];
const settings = ["Impostazioni"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
 
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
  
  navigate("/"+page);
  
  };

  const handleCloseUserMenu = () => {
    navigate("/Impostazioni")

  };

  return (
    <AppBar position="fixed" style={{backgroundColor:"#00a94e"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
   
       
       
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={() =>handleCloseNavMenu(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
       
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {JSON.parse(localStorage.getItem("utente")).auth===1?pages.map((page) => (

              <Button
                key={page}
                onClick={() =>handleCloseNavMenu(page)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            )):pagesUser.map((page) => (

              <Button
                key={page}
                onClick={() => handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page}
              </Button>
            ))}

          </Box>
          
          <Box sx={{ flexGrow: 0 }}>
     
              <IconButton onClick={JSON.parse(localStorage.getItem("utente")).auth===1?handleOpenUserMenu:""} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAMAAACdt4HsAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAZtQTFRF////p+HCbc6agNSn2/PmEq9bAKlOJLVn8/v3Lbht/v/+ddCft+fNB6tT8Pr1L7lvdtGgmd24Da1X2fLlFbBd+/79SMGAu+jQFrBd7/r0MLlvAalP0e/f8Pr0Mbpw+/78acyX4/bsGbJgV8aKFrFejtmxBatSILRkfNOkwurUCqxVC61WzO7ckNmy9Pv4Obx1A6pQhNWpPL13ye3aEK5Z0O/f4fXqNbtzAqpPg9Wp/f7+VMWJY8qTtObLHbNi4vXrHLNitebMYcqRVMWIeNGhPr555/fuCq1VC61Vw+vW9Pv3O713hdarkdqzAalOKbdr+f37werUBqtSDq5Xy+3b6ffwIrRmOr12mt25nN67WMeLr+THCKxTHLJi+P36ktqz0/DgFbBc/P79Qb97/v//hdaqwOrTBKpRF7FepuHBc9Ce6Pfvb86bRsF/G7Jh4PXp2PLkDq5YmNy49/z6RsF+nt683/TpwOrU7vnzVcaJbM2Z8fr1z+/e0/Dh/vn6+dDY/vv78ISY4hQ79KKx4x9E+c3V5CFG7nOK6ENi2oruhgAAAbNJREFUeJzF1OVTAlEUBfCH4oIgKCp2F4qIgoUIYmChYmInFnZ3t3+2DjPO3IG3u+/yHD1f7zm/IRYI+cso4kDi8XtlggCiQu/ViQIXoNEKfECSjg/QJwt8QIrABxhS+YC09Mg9DjBmRO1xgCJ6jwIys/iA7BzKHgNoc/mAPB1tzw7kF1D3zEBhEX3PDBioHwA7UFwismcEjKVwU4YGNOUVYGKqRANVZrCottRgAWstGNTZCBqwwYHdigbqG0Df3EiwQFMzqDtaCBZwtsK6S48G3PARbPMQLOBph+0OggXUnbDcRdCAF3a7e9CAshdU+/oJFvANwOogQQNDftAcHkEDo2OgOD5BsEBgEhanpmd+MssGOF0CS8SBuXk+ILDAtBcFFu1se1FgiXEvBiyv8AHBVdY9HVhb98svpYCNTdAIbVkisi0H7KhgQxF1l32Ud2FhL/ouB7jhff8ADRyGwPnomPIKpYGTU3j2OtHAmQNcTT7KXho4h/+C5gvaXhK4vILH6yAauLmFTwz1DXz/0mHnjt6JMfcPMSe8f3x6jjlh4OWVD3h7j30fBj4+OfKr38X/5Au5vepsd+0vcwAAAABJRU5ErkJggg==" />
              </IconButton>
          
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;