import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import {Container,Paper,Grid,TextField,Button} from '@mui/material';
import api from '../api/api.js';
import { logIn } from "../actions/index.js";
import { login,loginPersistant } from "../utils";
import { useSelector,useDispatch } from "react-redux";



const Login = () => {
const [username,setUsername]=useState("");
const [password,setPassword]=useState("");
const navigate = useNavigate();
const userLog = useSelector(state => state.user)
console.log(userLog);
const dispatch = useDispatch()

 useEffect(() => {
    document.body.style.backgroundColor = "#00a94e";

  });


const HandleLogin = async(event) =>{
  event.preventDefault();
    const response = await api
    .post('login',{username :username,password:password });
console.log(response);
if(response.data==="password errata" || response.data==="utente non trovato" ){
  alert("Username o Password errati");
}else{
  console.log(response.data.utente);
 login(response.data.utente);

dispatch(logIn(response.data.utente));

const responseSettings = await api.get('settings', {headers: {
  'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
}})

sessionStorage.setItem("settings",JSON.stringify(responseSettings.data.data[0]));


 if(response.data.utente.auth===1){

  navigate('/Clienti');
 }else{
  navigate('/PlcCliente');
 }
 //



}
}



return (

    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
      <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  style={{ minHeight: '100vh'}}
>

<Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  style={{ minHeight: '10vh' }}
>

<Paper elevation={3}  justifyContent="center" alignItems="center" style={{padding:"30px"}}> 
<img src={require('../img/arienti.png')} style= {{width:"100%"}}/>


<form >
<TextField
              margin="normal"
              fullWidth
              id="email"
              label="Username"
              name="email"
              autoComplete="email"
              autoFocus
              color="success"
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              color="success"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

<Button variant="contained" type="submit" color="success" style={{width:"100%",marginTop:"10px"}} onClick={(e)=>{HandleLogin(e)}}>
        LOG IN
      </Button>
      </form>
  </Paper>
  </Grid>
</Grid> 
      </Container>
    </React.Fragment>
)

}

export default Login;