import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import Header from "./Header";
import { Outlet } from 'react-router-dom';
import { Container } from "@mui/material";
const AppLayout = () => {


    useEffect(() => {
        document.body.style.backgroundColor = "#F7F7F7";
    
      });


return(<div>
     
         
       <Header/>
      
        <Outlet />
       
        </div>)

}



export default AppLayout;