import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isLogin,isToken,isAdmin } from '../utils';

function PrivateRouteAdmin({ children }) {
    const logok = isLogin();
    const admin= isAdmin();
  
    return logok && admin ? children : (logok?<Navigate to="/PlcCliente" />:<Navigate to="/login" />);

    
  }

export default PrivateRouteAdmin;
