import React, { useEffect, useState } from "react";
import api from "../api/api";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import SensorsIcon from '@mui/icons-material/Sensors';
import { Button, CardActionArea, CardActions, Container ,Paper,OutlinedInput,InputAdornment,IconButton,Avatar,CardHeader} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Edit } from "@mui/icons-material";
const Clienti = () =>{
    const navigate = useNavigate();

const [clienti,setClienti] = useState([]);
const [clientiVisibili,setClientiVisibili] = useState([]);
const userLog = useSelector(state => state.user)

    useEffect(() => {
        const fetchData = async () =>{
    await api.get('clienti',{withCredentials:false,headers: {

            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('utente')).token}`
        }})
        .then( res=> {

   setClienti(res.data.data)
   setClientiVisibili(res.data.data)
        })


    }

fetchData()

    },[]);


const handleSearch = (e) => {

if(e.target.value == ""){
    setClientiVisibili(clienti);
   
  }else{
    var clientiX = []; // popolo l'array 
  var i;
  for (i =0 ;i < clienti.length;i++){
    if (clienti[i].RagioneSociale.toLowerCase().includes((e.target.value.toLowerCase() ))){
      clientiX.push(clienti[i]);
    }
  }
  setClientiVisibili(clientiX);
  }


}



return (<div>
    
    <Container style={{marginTop:"100px"}}>
     
    <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={1}>
    <Grid item xs={12} style={{poistion:"fixed"}}>
    <Paper style={{width:"100%"}}>
      <OutlinedInput placeholder="Ricerca" style={{margin:20}} onChange={(e) => handleSearch(e)}
        startAdornment={
          <InputAdornment position="start">
       <i class="bi bi-search"></i>
               </InputAdornment>
        }
      />
      <Button onClick={()=>navigate(`/DettaglioCliente`, {state: {operazione:"Nuovo"}})} variant="contained" sx={{backgroundColor:"#00a94e",margin:"20px",'&:hover':{backgroundColor:"green"}}}  endIcon={<i class="bi bi-plus"></i>}>Aggiungi Nuovo</Button>
      </Paper>
    </Grid>
{
clientiVisibili.map((cliente,index)=>

<Grid item sm={12}>

<Card sx={{padding:"1px" }}>
  

<CardHeader
  avatar={
    <Avatar sx={{ bgcolor: "blue",border:"0px" }} src={cliente.Logo!==""?cliente.Logo:require("../img/arienti.png")}></Avatar>
  }
  title={cliente.RagioneSociale}
  titleTypographyProps={{fontSize:"30px"}}
  action={ <><Button endIcon={<SensorsIcon />} variant="contained" sx={{backgroundColor:"#00a94e",margin:"20px",'&:hover':{backgroundColor:"green"}}} size="small"   onClick={()=>navigate(`/PlcCliente`, {state: {cliente:cliente.ID}}) } >PLC</Button><Button endIcon={<Edit />} variant="contained" sx={{backgroundColor:"#00a94e",margin:"20px",'&:hover':{backgroundColor:"green"}}} size="small"  onClick={()=>navigate(`/DettaglioCliente`, {state: {operazione:"Modifica",cliente:cliente}})}>Modifica</Button></>}
/>

   

    </Card>

    </Grid>


)

}

        
        
      </Grid>
</Box>
</Container>
</div>)
}


export default Clienti;