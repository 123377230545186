import React from "react";
import reactDom from "react-dom";
import { BrowserRouter, Routes ,Route} from 'react-router-dom';
import Login from "./Login";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import Homepage from "./Homepage";
import AppLayout from "./AppLayout"
import Clienti from "./Clienti";
import PlcCliente from "./PlcCliente";
import PlcData from "./PlcData";
import "bootstrap-icons/font/bootstrap-icons.css";
import DettaglioCliente from "./DettaglioCliente";
import Settings from "./Settings";
import PlcDettaglioVentoAcqua from "./PlcDettaglioVentoAcqua";
import PlcDettaglioRaschiatore from "./PlcDettaglioRaschiatore";

export default class App extends React.Component {



render() {
    return (
      <div> 
        
      <BrowserRouter>
      <Routes>
     
        <Route path="/login" element={<Login />} />
        <Route path="/" element={ <PrivateRoute> <AppLayout /></PrivateRoute> } >
        <Route path="/Clienti" element={ <PrivateRouteAdmin> <Clienti /> </PrivateRouteAdmin> } /> 
        <Route path="/PlcCliente" element={ <PrivateRoute> <PlcCliente /> </PrivateRoute> } /> 
        <Route path="/PlcData" element={ <PrivateRoute> <PlcData /> </PrivateRoute> } /> 
        <Route path="/DettaglioCliente" element={ <PrivateRouteAdmin> <DettaglioCliente /> </PrivateRouteAdmin> } /> 
        <Route path="/Impostazioni" element={ <PrivateRoute> <Settings /> </PrivateRoute> } /> 
        <Route path="/DettaglioZona" element={ <PrivateRoute> <PlcDettaglioVentoAcqua /> </PrivateRoute> } /> 
        <Route path="/PlcDettaglioRaschiatore" element={ <PrivateRoute> <PlcDettaglioRaschiatore /> </PrivateRoute> } /> 
          </Route>
       {/*  <Route path="/" element={ <PrivateRoute> <AppContent /></PrivateRoute> } >
         <Route path="/" element={<Homepage/>}/>
             <Route path="checkHomepage" element={<CheckHomepage/>}/>
             <Route path="/dettaglioCliente" element={ <PrivateRoute> <ClientDetail /> </PrivateRoute> } /> 
             <Route path="/checkList" element={ <PrivateRoute> <CheckList /> </PrivateRoute> } /> 
             <Route path="/copertinaCliente" element={ <PrivateRoute> <Copertinacliente /> </PrivateRoute> } />
             <Route path="/clienti" element={ <PrivateRoute> <Clienti /> </PrivateRoute> } />
 </Route>
    */}
       





      </Routes>
    </BrowserRouter>
    </div>
  
    );
  }
}

/*


*/
    