const initialState = {
    user:""
}

const userReducer =(state = initialState,action) =>{

    if (action.type==="LOG_IN"){
        return {
            ...state,
            user: action.payload,
          };
        }

    return state;    
}

export default userReducer;