import react,{useState}  from 'react';

import {Modal,TextField,Typography,Button,Box} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = useState(false);
  const [seriale, setSeriale] = useState(props.seriale);
  const [descrizione, setDescrizione] = useState(props.descrizione);
  const [zone, setZone] = useState(props.zone);
  const [delta, setDelta] = useState(props.delta);
  const [raschiatori, setRaschiatori] = useState(props.raschiatori);
  const handleOpen = () => {setOpen(true);setSeriale(props.seriale);setDescrizione(props.descrizione);setZone(props.zone);setDelta(props.delta);setRaschiatori(props.raschiatori)}
  const handleClose = () => setOpen(false);


const handleChange = (e) => {
setSeriale(e.target.value)

}

const handleButton = () =>{


    handleClose();
    props.func(seriale,descrizione,zone,raschiatori,delta,props.operazione,props.zoneBackup,props.raschiatoriBackup);
}

  return (
    <div>{
      props.operazione==="Modifica"?<Button onClick={handleOpen} id={props.idButton} sx={{display:"None"}}  endIcon={<i class="bi bi-plus"></i>}>Aggiungi</Button>:
        <Button onClick={handleOpen} id={props.idButton} sx={{backgroundColor:"#00a94e",color:"white",margin:"5px",'&:hover':{backgroundColor:"green",color:"white"}}}  endIcon={<i class="bi bi-plus"></i>}>Aggiungi</Button>
    }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
           {props.operazione==="Modifica"?"Modifica PLC":"Aggiungi PLC"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
                fullWidth
               onChange={(e) => handleChange(e)}
                label="Seriale"
                name="RagioneSociale"
                required
                
                InputProps={{
                  readOnly: props.operazione==="Modifica"?true:false,
                }}
                value={seriale}
                variant="outlined"
              />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
                fullWidth
               onChange={(e) => setDescrizione(e.target.value)}
                label="Descrizione"
                name="Descrizione"
                required
                value={descrizione}
                variant="outlined"
              />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
                fullWidth
               onChange={(e) => setZone(e.target.value)}
                label="Zone"
                name="Zone"
                required
                value={zone}
                variant="outlined"
              />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
                fullWidth
               onChange={(e) => setRaschiatori(e.target.value)}
                label="Raschiatori"
                name="Raschiatori"
                required
                value={raschiatori}
                variant="outlined"
              />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <TextField
                fullWidth
               onChange={(e) => setDelta(e.target.value)}
                label="Delta"
                name="Delta"
                required
                value={delta}
                variant="outlined"
              />
          </Typography>
          
          <Button variant="contained" onClick={() => handleButton()} sx={{backgroundColor:"#00a94e",color:"white",margin:"20px",'&:hover':{backgroundColor:"green",color:"white"}}}  endIcon={<i class="bi bi-check"></i>}>Salva</Button>
        </Box>
      </Modal>
    </div>
  );
}